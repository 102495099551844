export const API_ENDPOINT = "https://aiimagecreator.ru/";
// export const API_ENDPOINT = "http://localhost:8080/";

export function initHref() {

    let initUrl = window.location.search

    if (window.Telegram.WebApp.initData !== '') {
        initUrl = "?" + window.Telegram.WebApp.initData;
    }

    return initUrl;
}