import React, {createContext, useEffect, useReducer} from "react";
import { vkReducer } from "./vkReducer";
import bridge from '@vkontakte/vk-bridge';
import {
    GET_REWARD_ADS_ERROR,
    GET_REWARD_ADS_SUCCESS,
    GET_VK_USER_INFO_ERROR,
    GET_VK_USER_INFO_SUCCESS,
    GET_VK_USER_SHOW_LOADING
} from "../types";

export const VkContext = createContext(null);

export const VkState = ({children}) => {

    const initialState = {
        isLoading: false,
        vkUser: null,
        error: null,
        reward: false
    };

    const [ state, dispatch ] = useReducer(vkReducer, initialState);

    const loadRewardContent = () => {
        bridge.send('VKWebAppCheckNativeAds', {
            ad_format: 'reward',
            use_waterfall: true
        }).then((data) => {
            if (data.result) {
                console.log("Реклама загружена")
                dispatch({type: GET_REWARD_ADS_SUCCESS})
            } else {
                dispatch({type: GET_REWARD_ADS_ERROR})
            }
        })
            .catch((error) => {
                console.log(error);
                dispatch({type: GET_REWARD_ADS_ERROR})
            });
    }

    useEffect(() => {

        loadRewardContent()

        vkSubscribe()
        fetchUser()
        return () => {

        }
    }, [])
    const showLoader = () => dispatch({ type: GET_VK_USER_SHOW_LOADING });

    const fetchUser = () => {
        showLoader()
        bridge.send("VKWebAppGetUserInfo");
    }

    const vkSubscribe = () => {

        bridge.subscribe(event => {

                if (!event.detail) {
                    return;
                }

                const { type, data } = event.detail;
            //
            // if (type !== 'webpackClose' && !data) {
            //     const payload = 'VKUndefined'
            //     dispatch({type: GET_VK_USER_INFO_SUCCESS, payload: payload})
            // }

            switch (type) {
                    case 'VKWebAppInitResult':
                        break;
                    case 'VKWebAppUpdateConfig':
                        const scheme = data.scheme;
                        let schemeAttribute = document.createAttribute('scheme');
                        schemeAttribute.value = scheme ? scheme : 'bright_light';
                        document.body.attributes.setNamedItem(schemeAttribute);
                        break;
                    case 'VKWebAppOpenCodeReaderResult':
                        break;
                    case 'VKWebAppGetConfigResult':
                        break;
                    case 'SetSupportedHandlers': // нельзя удалять, а то ошибка возникает
                        break;
                    case 'VKWebAppGetUserInfoFailed':
                        dispatch({ type: GET_VK_USER_INFO_ERROR, error: data })
                        break;
                    case 'VKWebAppGetUserInfoResult':
                        dispatch({ type: GET_VK_USER_INFO_SUCCESS, payload: data })
                        break;
                    case 'VKWebAppShowOrderBoxResult':
                        break;
                    case 'VKWebAppCheckBannerAdResult':
                        break
                    case 'VKWebAppShowBannerAdResult':
                        break
                    case 'VKWebAppHideBannerAdResult':
                        break
                    case 'VKWebAppCheckNativeAdsResult':
                        break
                    case 'VKWebAppStorageGetResult':
                        break
                    case 'VKWebAppStorageSetResult':
                        break
                    case 'VKWebAppAddToFavoritesResult':
                        break
                    case 'VKWebAppAddToHomeScreenInfoResult':
                        break
                    case 'VKWebAppAddToHomeScreenResult':
                        break
                    case 'VKWebAppRecommendResult':
                        break
                    case 'VKWebAppShowWallPostBoxResult':
                        break
                    case 'VKWebAppShowBannerAdFailed':
                        break
                    case 'VKWebAppHideBannerAdFailed':
                        break
                    case 'VKWebAppGetLaunchParamsResult':
                        break
                    case 'VKWebAppGetLaunchParamsFailed':
                        break
                    case 'VKWebAppInitFailed':
                        break
                    case 'vk-connect':
                        break
                    default:
                        console.log(data)
                }
            }
        )
    }

    return (
        <VkContext.Provider value={{
            fetchUser,
            vkSubscribe,
            isLoading: state.isLoading,
            vkUser: state.vkUser,
            error: state.error,
        }}>
            {children}
        </VkContext.Provider>
    )
}
