import React, {useContext, useEffect, useRef} from 'react';
import {Avatar, Checkbox, Group, Platform, Separator, SimpleCell, Spacing, Spinner, usePlatform} from '@vkontakte/vkui';
import {Icon28CoinsOutline} from "@vkontakte/icons";
import {useDimensions} from "../../utils/useDimensions";
import {ProfileContext} from "../../context/profile/ProfileState";
import {VkContext} from "../../context/vk/VkState";
import {ThumbnailCards} from "../../components/ThumbnailCards";
import {getResources} from "../../utils/languages";

const telegram = window.Telegram.WebApp

export const ProfilePanel = ({ openPaymentCard, openModal }) => {

    const platform = usePlatform();

    const ref = useRef(null)
    const {width, cardWidth} = useDimensions(ref)

    const {isLoading, user, setImage, images, discover, changeDiscover, fetchImages, page, setPage, last, like, unmount} = useContext(ProfileContext)
    const {vkUser} = useContext(VkContext)

    const selectImage = (image) => {
        setImage(image)
        openModal()
    }

    useEffect(() => {
        fetchImages(page, 10, 'desc');
    }, [page, discover]);

    useEffect(() => {

        const widthCount = Math.floor(width / cardWidth);
        const heightCount = Math.floor(window.innerHeight / cardWidth);
        const cardCount = widthCount * heightCount;

        if (images.length < cardCount && !isLoading && !last) {
            setPage();
        }

    }, [images]);

    useEffect(() => {
        return () => {
            unmount()
        }
    }, [])

    const setName = () => {

        let lastName = '';
        let firstName = '';

        if (telegram.initData !== '') {
            if (telegram.initDataUnsafe.user.first_name !== undefined) {
                firstName = telegram.initDataUnsafe.user.first_name
            }

            if (telegram.initDataUnsafe.user.last_name !== undefined) {
                lastName = telegram.initDataUnsafe.user.last_name
            }
            return lastName + ' ' + firstName;
        }

        if (vkUser && vkUser.last_name) {
            lastName = vkUser.last_name
        }

        if (vkUser && vkUser.first_name) {
            firstName = vkUser.first_name
        }

        return lastName + ' ' + firstName;
    }

    const setCity = () => {
        let city = '';
        if (vkUser && vkUser.city && vkUser.city.title) {
            city = vkUser.city.title
        }

        return city;
    }

    const setAvatar = () => {
        let avatar = '';
        if (telegram.initData !== '') {
            if (telegram.initDataUnsafe.user.photo_url !== undefined) {
                avatar = telegram.initDataUnsafe.user.photo_url
            }
            return avatar;
        }
        if (vkUser && vkUser.photo_200) {
            avatar = vkUser.photo_200;
        }

        return avatar;
    }

    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight
            const currentHeight = e.target.documentElement.scrollTop + window.innerHeight
            if (currentHeight + 1 >=  scrollHeight && !isLoading && !last) {
                setPage();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading]);

    const isVisibleBalance = () => {
        if (telegram.initData !== '') {
            return true
        }
        return platform !== Platform.IOS;

    };

    return (
        <Group>
            <Spacing size={12} />
            <SimpleCell
                hasHover={false}
                hasActive={false}
                before={<Avatar size={72} src={setAvatar()} />}
                subtitle={setCity()}
            >
                {setName()}
            </SimpleCell>
            { isVisibleBalance() ?
                <>
                    <Spacing size={12} />
                    <SimpleCell
                        before={<Icon28CoinsOutline />}
                        subtitle={user && user.balance}
                        onClick={openPaymentCard}
                    >
                        {getResources().balance}
                    </SimpleCell>
                </>
                :
                null
            }
            <Spacing size={12} />
            <Separator />
            <div style={{paddingTop: 8, display: 'flex', justifyContent: 'flex-end'}}>
                <Checkbox hasHover={false} hasActive={false}
                          defaultChecked={discover}
                          onChange={() => changeDiscover()}>
                    {getResources().discover}
                </Checkbox>
            </div>
            <div ref={ref}>
                <ThumbnailCards
                    images={images}
                    width={cardWidth}
                    height={cardWidth}
                    onClick={(image) => selectImage(image)}
                    like={like}
                />
                {isLoading &&  <Spinner size="regular" style={{ margin: '20px 0' }} />}
            </div>
        </Group>
    )
};
