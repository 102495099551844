import React from 'react';
import {
    Caption,
    Div,
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderClose,
    Paragraph,
    Platform,
    Title,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import {Icon24Dismiss} from "@vkontakte/icons";

export const DescriptionModalPageEn = ({id, modalBack}) => {

    const platform = usePlatform();
    const { sizeX } = useAdaptivityConditionalRender();

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            size='s'
            hideCloseButton={platform === Platform.IOS}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === Platform.ANDROID && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
                        )
                    }
                    after={
                        platform === Platform.IOS && (
                            <PanelHeaderButton onClick={modalBack}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    How to create a prompt?
                </ModalPageHeader>
            }>
            <Group>
                <Div>
                    Prompt is an instruction or request that you send to AI Image to get a specific result. Understanding how to formulate prompts correctly can significantly improve your experience interacting with the app. In this article, we will discuss how to create an effective prompt for AI Image with specific examples.
                </Div>
                <Div>
                    <Title Component="h1">
                        Clarity and Specificity
                    </Title>
                </Div>
                <Div>
                    One of the key points in creating a prompt is its clarity and specificity.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Bad example:</span> "Make an image."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Good example:</span> "Create an image of a sunset on the beach with palm trees."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/67c431f3fa0f19a244036.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Create an image of a sunset on the beach with palm trees</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Use Styles
                    </Title>
                </Div>
                <Div>
                    Styles allow you to create images for any purpose.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Style:</span> Impressionism
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/e3aa45bc1bbf9159c1627.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Create an image of a sunset on the beach with palm trees.</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Limit the Amount of Information
                    </Title>
                </Div>
                <Div>
                    A concise and clear description will help your request be executed faster and more accurately.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Bad example:</span> "I need an image with lots of flowers, such as roses, tulips, orchids, and they will all be around a pond, on which swans float, and around the pond there will be butterflies..."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Good example:</span> "Create an image of a pond with swans and the surrounding flowers."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/e25666ad7af368f9f8447.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Create an image of a pond with swans and the surrounding flowers</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Avoid Ambiguity
                    </Title>
                </Div>
                <Div>
                    The clearer your request, the more precise the result will be.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Bad example:</span> "Make something beautiful."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Good example:</span> "Create an image of a mountain landscape at sunset."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/d5a99ad52865667354759.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Create an image of a mountain landscape at sunset</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Experiment
                    </Title>
                </Div>
                <Div>
                    Don't be afraid to experiment with different formulations to get the best result.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Bad example:</span> "I need a picture of a girl."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Good example:</span> "Dark frame, photo of a pretty 24-year-old woman, perfect eyes, natural skin, looking at the viewer, cinematic frame."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/5d72ab34caae0fbf66606.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Dark frame, photo of a pretty 24-year-old woman, perfect eyes, natural skin, looking at the viewer, cinematic frame</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        More Examples:
                    </Title>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Style:</span> Greg Tocchini
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/2dea58a1b001651006584.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Masterpiece, best quality, extremely detailed, closeup full body view, strikingly beautiful young Icelandic woman in a cluttered bedroom, detailed face, eyes closed, frowning, vivid dyed ultraviolet hair, relaxed, under lit, narrow waist, wide hips</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Style:</span> Charcoal Sketch
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/72c3e0a990582b32c04cf.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Disney Tinkerbell, full body</Caption>
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/8275e0cc90f09cc93e4c9.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">A messy, chaotic artist's workshop, inside is a cute robot painting a picture. The picture is of another robot</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Style:</span> Watercolor
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/679e93f1856d299996e3f.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Back side view of a girl sitting on a open window, looking sideways, blue striped shirt, pink skirt, inside a house, sunset, blue sky</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Style:</span> Anime
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/f3e31e4fa886c13ce9b7f.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Masterpiece, best quality, highres, intricate detail, 1 girl, light brown hair, brown eyes, school uniform, parted lips, high quality eyes, high quality iris, best quality, detailed, quality, lifelike, precise, vibrant</Caption>
                </Div>
                <Div style={{textAlign: 'center'}}>
                    <Paragraph>
                        Don't miss the chance to unlock the full potential of artificial intelligence in creating visual content. Try AI Image right now and see its capabilities in practice!
                    </Paragraph>
                </Div>
            </Group>

        </ModalPage>
    )
}
