import React from "react";
import {AiCard} from "./AiCard";
import {CardGrid} from "@vkontakte/vkui";
import {AiVideoCard} from "./AiVideoCard";

export const ThumbnailCards = ({images, width, height, onClick, like}) => {

    const getComponent = (image) => {
        if (image.status === 'IMAGE_CLOSED') {
            return (
                <AiCard
                    key={'AiCard' + image.uid}
                    onClick={() => onClick(image)}
                    width={width}
                    height={height}
                    image={image}
                    resolution='thumbnail'
                    like={like}
                />
            );
        } else if (image.status === 'VIDEO_CLOSED') {
            return (
                <AiVideoCard
                    key={'AiCard' + image.uid}
                    onClick={() => onClick(image)}
                    width={width}
                    height={height}
                    image={image}
                    like={like}
                />
            )
        }
    }

    return (
        <CardGrid spaced>
            { images.map((image, index) =>
                getComponent(image)
            )}
        </CardGrid>
    )
}