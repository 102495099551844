import React from 'react';
import {AdaptivityProvider, AppRoot, ConfigProvider} from '@vkontakte/vkui';
import vkBridge from '@vkontakte/vk-bridge';
import {ContextProvider} from "./context/ContextProvider";
import {MainRoot} from "./pages/MainRoot";
import {useAdaptivity, useAppearance, useInsets} from "@vkontakte/vk-bridge-react";
import {transformVKBridgeAdaptivity} from "./utils/transformVKBridgeAdaptivity";

export const App = () => {

	const vkBridgeAppearance = useAppearance() || undefined;
	const vkBridgeAdaptivityProps = transformVKBridgeAdaptivity(useAdaptivity());
	const vkBridgeInsets = useInsets() || undefined;

	return (
		<ContextProvider>
			<ConfigProvider
				appearance={vkBridgeAppearance}
				hasCustomPanelHeaderAfter={true}
				isWebView={vkBridge.isWebView()}
			>
				<AdaptivityProvider {...vkBridgeAdaptivityProps}>
					<AppRoot safeAreaInsets={vkBridgeInsets}>
						<MainRoot/>
					</AppRoot>
				</AdaptivityProvider>
			</ConfigProvider>
		</ContextProvider>
	);
}
