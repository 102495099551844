import {
    GET_PAYMENT_URL_START,
    GET_PRODUCT_URL_ERROR,
    GET_PRODUCT_URL_SUCCESS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_START,
    GET_PRODUCTS_SUCCESS,
    SET_PRODUCTS
} from "../types";

const handlers = {
    [GET_PRODUCTS_START]: state => ({...state, isLoading: true, products: [], error: null}),
    [GET_PRODUCTS_SUCCESS]: (state, { payload }) => ({...state, products: payload.products, title: payload.title, subtitle: payload.subtitle, isLoading: false}),
    [GET_PRODUCTS_ERROR]: (state, { payload }) => ({...state, products: [], isLoading: false, error: payload}),
    [SET_PRODUCTS]: (state, { payload }) => ({...state, product: payload}),
    [GET_PAYMENT_URL_START]: state => ({...state, urlIsLoading: true, urlError: null}),
    [GET_PRODUCT_URL_SUCCESS]: (state, { payload }) => ({...state, paymentUrl: payload, urlIsLoading: false}),
    [GET_PRODUCT_URL_ERROR]: (state, { payload }) => ({...state, paymentUrl: null, urlIsLoading: false, urlError: payload}),
    DEFAULT: state => state
};

export const paymentReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};