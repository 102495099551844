import React, {useContext, useEffect} from 'react';
import {
    Button, ButtonGroup,
    FormItem,
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderClose,
    Platform,
    Textarea,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import {
    Icon20BlockOutline,
    Icon20LikeOutline,
    Icon20ViewOutline,
    Icon24Dismiss,
    Icon24RefreshOutline,
    Icon28LikeFillRed
} from "@vkontakte/icons";
import {ImageCard} from "../../components/ImageCard";
import classes from "../../components/css/AiCard.module.css";
import {CreatorContext} from "../../context/creator/CreatorState";
import {DiscoverContext} from "../../context/discover/DiscoverState";
import {VideoCard} from "../../components/VideoCard";
import {getResources} from "../../utils/languages";

const MODAL_PAGE_CREATE = 'create_image_screen';
const MODAL_CARD_COMPLAINT = 'complaint_card';
const MODAL_CARD_ADS = 'ads_card';

export const DiscoverModalPage = ({id, modalBack, changeActiveModal}) => {

    const platform = usePlatform();
    const { sizeX } = useAdaptivityConditionalRender();

    const { image, like } = useContext(DiscoverContext)
    const { isLoading, userIsPaid, setPrompt, setStyle, setResolution, createImage, status, error, unmount } = useContext(CreatorContext)

    useEffect(() => {

        return () => {
            unmount(false)
        }
    }, [])

    useEffect(() => {
        if (status === 'START_GENERATION') {
            changeActiveModal(MODAL_PAGE_CREATE)
        }
    }, [status])

    const onCreate = () => {
        if (image !== null) {

            setPrompt(image.user_message)
            setStyle(image.style)
            setResolution(image.resolution)

            if (userIsPaid || platform === Platform.IOS) {
                createImage(image.user_message, image.style, image.resolution);
            } else {
                changeActiveModal(MODAL_CARD_ADS)
            }
        }
    }

    const addButtonGroup = () => {
        if (isLoading) {
            return null;
        }
        if (image.status === 'IMAGE_CLOSED') {
            return (
                <ButtonGroup mode="horizontal" gap="m" stretched>
                    <Button
                        style={{marginBottom:12}}
                        before={<Icon24RefreshOutline />}
                        size="l"
                        align="center"
                        appearance="accent"
                        mode="outline"
                        stretched
                        onClick={() => onCreate()}>
                        {getResources().try}
                    </Button>
                </ButtonGroup>
            )
        }
    }

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            size='s'
            hideCloseButton={platform === Platform.IOS}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === Platform.ANDROID && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
                        )
                    }
                    after={
                        platform === Platform.IOS && (
                            <PanelHeaderButton onClick={modalBack}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                </ModalPageHeader>
            }
        >
            <Group style={{marginLeft: 16, marginRight:16}}>
                { image &&
                    <>
                        { image.status === 'IMAGE_CLOSED' &&
                            <ImageCard uid={image.uid} style={image.style} prompt={image.user_message}/>
                        }
                        { image.status === 'VIDEO_CLOSED' &&
                            <VideoCard uid={image.uid} image={image}/>
                        }

                        <div className={classes.like_container}>
                            <button
                                className={classes.like_container}
                                onClick={() => like(image.uid)}
                            >
                                {image.i_like ?
                                    <Icon28LikeFillRed width={20} height={20}/>
                                    :
                                    <Icon20LikeOutline width={20} height={20}/>
                                }
                                <div style={{paddingTop: 4, paddingLeft: 4, fontSize: 12}}>
                                    {image.likes}
                                </div>
                            </button>
                            <button className={classes.like_container}>
                                <Icon20ViewOutline/>
                                <div style={{paddingTop: 4, paddingLeft: 4, fontSize: 12}}>
                                    {image.views}
                                </div>
                            </button>

                            <div className={classes.block_container}/>

                            <button className={classes.block_right}>
                                <Icon20BlockOutline
                                    width={20} height={20}
                                    onClick={() => changeActiveModal(MODAL_CARD_COMPLAINT)}
                                />
                            </button>
                        </div>
                        <div style={{marginTop: 8, marginBottom: 24}}>
                            <FormItem
                                status={ error ? 'error' : '' }
                                bottom={ error ? error : '' }
                                noPadding>
                                <Textarea disabled={true} value={image && image.user_message}/>
                            </FormItem>
                        </div>
                        { addButtonGroup() }
                    </>
                }
            </Group>
        </ModalPage>
    )
}



