import React, {useContext, useEffect, useState} from "react";
import classes from './css/AiCard.module.css'
import axios from "axios";
import {API_ENDPOINT, initHref} from "../utils/initHref";
import {Spinner} from "@vkontakte/vkui";
import refresh from '../img/refresh_outline_28.svg'
import {CacheContext} from "../context/cache/CacheState";
import {Icon20LikeOutline, Icon28LikeFillRed} from '@vkontakte/icons';

export const AiCard = ({ width, height, image, resolution, onClick, like }) => {

    const [src, setSrc] = useState(null);

    const {cache, setCache} = useContext(CacheContext)

    useEffect(() => {
        if (cache[image.uid]) {
            setSrc(cache[image.uid])
        } else {
            axios.get(API_ENDPOINT + 'image/' + image.uid + initHref(), {
                params: {
                    resolution: resolution
                }
            }).then(response => {
                const data = response.data
                if (data.status === 'OK') {
                    const src = data.data
                    setSrc(src);
                    setCache(image.uid, src)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    },[])

    return (
        <div style={{padding: 6, width: width, height: height}}>
            { src !== null ?
                <div className={classes.container} style={{height: height}}>
                    <img
                        className={classes.image}
                        alt=''
                        width='100%'
                        height='100%'
                        src={src}
                        onClick={onClick && onClick}
                        onError={(e) => {
                            e.target.src=refresh
                        }}
                    />
                    <button className={classes.likes} onClick={() => like(image.uid)}>
                        { image.i_like ?
                            <Icon28LikeFillRed width={20} height={20}/>
                            :
                            <Icon20LikeOutline width={20} height={20}/>
                        }
                        <div style={{paddingTop: 4, paddingLeft: 4, fontSize: 12}}>
                            {image.likes}
                        </div>
                    </button>

                </div>
                :
                <Spinner size="medium"/>
            }
        </div>

    )
}