import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Caption,
    ModalPage,
    Platform, Snackbar,
    Spinner,
    Title,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import background from '../../img/000243379754610.jpeg'
import {PaymentContext} from "../../context/payment/PaymentState";
import bridge from '@vkontakte/vk-bridge';
import {ProfileContext} from "../../context/profile/ProfileState";
import {getResources} from "../../utils/languages";
import {Icon28CheckCircleOutline} from "@vkontakte/icons";

const MODAL_CARD_PAYMENT = 'payment_card';

const telegram = window.Telegram.WebApp

const urlParams = new URLSearchParams(window.location.search);
const vk_language = urlParams.get('vk_language');

export const PaymentModalPage = ({ id, modalBack, changeActiveModal }) => {

    const divRef = useRef(null);

    const [snackbar, setSnackbar] = React.useState(null);
    const { isLoading, products, title, subtitle, error, getProducts, setProduct } = useContext(PaymentContext)
    const { user, fetchImages } = useContext(ProfileContext)

    const platform = usePlatform();
    const { sizeX } = useAdaptivityConditionalRender();
    const [language, setLanguage] = useState('ru')

    useEffect(() => {
        if (vk_language !== null
            && vk_language !== undefined) {
            if (vk_language === 'ru') {
                setLanguage('ru')
            }
        } else if (telegram.initData !== '') {
            if (telegram.initDataUnsafe.user.language_code === 'ru') {
                setLanguage('en')
            }
        } else {
            setLanguage('en')
        }
        getProducts()
    }, [])

    useEffect(() => {
        const divElement = divRef.current;

        const handleCodeClick = async (event) => {
            const target = event.target;
            if (target.tagName === 'CODE') {
                const linkText = target.textContent;

                try {
                    await navigator.clipboard.writeText(linkText);
                    openSuccess()
                } catch (err) {
                    console.error("Ошибка при копировании текста: ", err);
                }
            }
        };

        if (divElement) {
            divElement.addEventListener('click', handleCodeClick);
        }

        return () => {
            if (divElement) {
                divElement.removeEventListener('click', handleCodeClick);
            }
        };
    }, []);

    const openSuccess = () => {
        if (snackbar) return;
        setSnackbar(
            <Snackbar
                onClose={() => setSnackbar(null)}
                before={<Icon28CheckCircleOutline fill="var(--vkui--color_icon_positive)" />}
            >
                {getResources().snackbar}
            </Snackbar>
        );
    };

    const selectProduct = (product) => {

        if (telegram.initData !== '') {
            setProduct(product)
            changeActiveModal(MODAL_CARD_PAYMENT)
        } else {
            bridge.send('VKWebAppShowOrderBox',
                {
                    type: 'item',
                    item: product.productId,
                })
                .then( (data) => {
                        console.log('Purchase successful.', data)
                        fetchImages(1,1,'desc')
                    }
                )
                .catch( (e) => {
                    console.log('Error!', e) }
                );
        }
    }

    const getDescription = (product) => {

        if (vk_language !== null
            && vk_language !== undefined) {
            if (vk_language === 'ru') {
                return product.descriptionRu
            }
        }

        if (telegram.initData !== '') {
            if (telegram.initDataUnsafe.user.language_code === 'ru') {
                return product.descriptionRu
            }
            return product.descriptionEn
        }

        return product.descriptionEn
    }

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            size='s'
            hideCloseButton={platform === Platform.IOS}
        >
            <div style={{
                height: 160,
                padding:16,
                backgroundSize: 'cover',
                backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1)), url(${background})`
            }}/>
            <div style={{padding: 16}}>
                <Title style={{marginBottom: 8, marginTop: 8, textAlign: 'center'}} Component="h2" weight="1">
                    {title}
                </Title>

                <div style={{textAlign: "center", paddingTop: 16, paddingBottom: 16}}>
                    <div ref={divRef} dangerouslySetInnerHTML={{__html: subtitle}}/>
                </div>

                <ButtonGroup mode="vertical" gap="m" stretched>
                {products.map((product, index) =>
                        <Button
                            key={"product" + index}
                            size="s"
                            appearance="accent"
                            mode="outline"
                            stretched
                            onClick={() => selectProduct(product)}
                        >
                            {getDescription(product)}
                        </Button>
                    )}
                    {isLoading && <Spinner size="large"/>}
                </ButtonGroup>
                <div style={{padding: 20, textAlign: 'center'}}>
                    <Caption level='2'>
                        <a href={`https://aiimagecreator.ru/${language}/license_agreement.html`} target='_blank'>{getResources().terms}</a>
                        &nbsp;|&nbsp;
                        <a href={`https://aiimagecreator.ru/${language}/privacy_ru.html`} target='_blank'>{getResources().policy}</a>
                    </Caption>
                </div>
            </div>
            {snackbar}
        </ModalPage>
    )
}



