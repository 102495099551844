import React from "react";
import {ModalRoot} from "@vkontakte/vkui";
import {CreateModalPage} from "./CreateModalPage";
import {DiscoverModalPage} from "./DiscoverModalPage";
import {PaymentModalPage} from "./PaymentModalPage";
import {ProfileModalPage} from "./ProfileModalPage";
import {PaymentModalCard} from "./PaymentModalCard";
import {ComplaintModalCard} from "./ComplaintModalCard";
import {DescriptionModalPage} from "./DescriptionModalPage";
import {DeleteModalCard} from "./DeleteModalCard";
import {AdsModalCard} from "./AdsModalCard";
import {DescriptionModalPageEn} from "./DescriptionModalPageEn";

const MODAL_PAGE_CREATE = 'create_image_screen';
const MODAL_PAGE_DISCOVER = 'discover_image_screen';
const MODAL_PAGE_PROFILE = 'profile_image_screen';
const MODAL_CARD_PAYMENT = 'payment_card';
const MODAL_CARD_COMPLAINT = 'complaint_card';
const MODAL_PAGE_PAYMENT = 'payment_page';
const MODAL_PAGE_DESCRIPTION = 'description_page';
const MODAL_PAGE_DESCRIPTION_EN = 'description_page_en';
const MODAL_PAGE_DELETE = 'delete_image_screen';
const MODAL_CARD_ADS = 'ads_card';

export const AiBaseModal = ({activeModal, modalBack, changeActiveModal}) => {

    return (
        <ModalRoot activeModal={activeModal} onClose={modalBack}>
            <CreateModalPage id={MODAL_PAGE_CREATE} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <DiscoverModalPage id={MODAL_PAGE_DISCOVER} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <ProfileModalPage id={MODAL_PAGE_PROFILE} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <PaymentModalCard id={MODAL_CARD_PAYMENT} modalBack={modalBack}/>
            <ComplaintModalCard id={MODAL_CARD_COMPLAINT} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <DeleteModalCard id={MODAL_PAGE_DELETE} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <PaymentModalPage id={MODAL_PAGE_PAYMENT} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
            <DescriptionModalPage id={MODAL_PAGE_DESCRIPTION} modalBack={modalBack}/>
            <DescriptionModalPageEn id={MODAL_PAGE_DESCRIPTION_EN} modalBack={modalBack}/>
            <AdsModalCard id={MODAL_CARD_ADS} modalBack={modalBack} changeActiveModal={changeActiveModal}/>
        </ModalRoot>
    )
}