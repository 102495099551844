import {
    GET_REWARD_ADS_ERROR,
    GET_REWARD_ADS_SUCCESS,
    GET_VK_USER_INFO_ERROR,
    GET_VK_USER_INFO_SUCCESS,
    GET_VK_USER_SHOW_LOADING
} from "../types";

const handlers = {
    [ GET_VK_USER_SHOW_LOADING ]: state => ({ ...state, isLoading: true }),
    [ GET_VK_USER_INFO_SUCCESS ]: (state, {payload}) => ({ ...state, vkUser: payload, isLoading: false }),
    [ GET_VK_USER_INFO_ERROR ]: (state, {error}) => ({ ...state, isLoading: false, error: error }),
    [ GET_REWARD_ADS_SUCCESS ]: (state, {error}) => ({ ...state, reward: true }),
    [ GET_REWARD_ADS_ERROR ]: (state, {error}) => ({ ...state, reward: false }),
    DEFAULT: state => state
};

export const vkReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
