import React, {useEffect} from "react";
import classes from './css/AiCard.module.css'
import {Icon36DoneOutline} from "@vkontakte/icons";
import refresh from '../img/refresh_outline_28.svg'

export const StyleCard = ({style, selected, paid, onClick}) => {

    return (
        <div className={!paid ? classes.container : classes.container_not_paid}
             style={{padding: 6}}>
            <img
                className={`${classes.image} ${selected ? classes.selected : ''}`}
                alt=''
                width={172}
                height={172}
                src={style.image}
                onClick={onClick && onClick}
                onError={(e) => {
                    e.target.src=refresh
                }}
            />
            { selected &&
                <div className={classes.icon_down}>
                    <Icon36DoneOutline/>
                </div>
            }
            { style &&
                <div className={classes.bottomLeft}>
                    {style.title}
                </div>
            }
        </div>
    )
}