import React, {useContext} from 'react';
import {Button, ModalCard, Spacing} from "@vkontakte/vkui";
import {Icon56MoneyTransferOutline} from "@vkontakte/icons";
import {PaymentContext} from "../../context/payment/PaymentState";
import {getResources} from "../../utils/languages";

export const PaymentModalCard = ({id, modalBack}) => {

    const { urlIsLoading, product, paymentUrl, urlError } = useContext(PaymentContext)

    const openInNewTab = () => {
        window.open(paymentUrl, "_blank", "noreferrer");
    };

    return (
        <ModalCard
            id={id}
            onClose={modalBack}
            icon={<Icon56MoneyTransferOutline />}
            header={product && getResources().youAreBuying + product.descriptionRu}
            subheader={getResources().pressPayToProceed}
            actions={
                <React.Fragment>
                    <Spacing size={16} />
                    <Button
                        size="l"
                        mode="primary"
                        stretched
                        disabled={urlIsLoading || urlError !== null}
                        loading={urlIsLoading || urlError !== null}
                        onClick={() => openInNewTab()}
                    >
                        {getResources().pay}
                    </Button>
                </React.Fragment>
            }
        ></ModalCard>
    )
}



