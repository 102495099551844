import React, {useEffect, useState} from 'react';
import {PanelHeader, usePlatform} from "@vkontakte/vkui";

export const AiPanelHeader = ({title}) => {

    const platform = usePlatform();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    }

    return (
        // Свойство separator удалено, используйте свойство delimiter.
        <PanelHeader delimiter='auto'>
            {title && <div style={platform === 'vkcom' ? {fontSize: 15} : {fontSize: 32}}>{title}</div>}
        </PanelHeader>
    )
}