import {SET_CACHE} from "../types";

const handlers = {
    [SET_CACHE]: (state, {payload}) => (
        { ...state,
            cache: {
                ...state.cache,
                [payload.uid]: payload.src
            }}
    ),
    DEFAULT: state => state
};

export const cacheReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};