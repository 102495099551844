import React, {createContext, useReducer} from 'react';
import {profileReducer} from "./profileReducer";
import {
    CHANGE_DISCOVER,
    DELETE_STATUS,
    DELETE_SUCCESS,
    GET_PROFILE_START,
    GET_PROFILE_SUCCESS,
    LIKE_SUCCESS,
    PROFILE_UPSCALE_ERROR,
    PROFILE_UPSCALE_START,
    SET_PROFILE_IMAGE,
    SET_PROFILE_PAGE,
    UNMOUNT_PROFILE,
    UPDATE_DISCOVER_SUCCESS
} from "../types";
import axios from "axios";
import {API_ENDPOINT, initHref} from "../../utils/initHref";
import {handleError} from "../../utils/handleError";

export const ProfileContext = createContext(null);

export const ProfileState = ({children}) => {

    const initialState = {
        user: null,
        isLoading: false,
        page: 0,
        images: [],
        image: null,
        last: false,
        discover: false,
        statusDelete: null
    };

    const [ state, dispatch ] = useReducer( profileReducer, initialState );

    const changeDiscover = () => {
        dispatch({type: CHANGE_DISCOVER});
    }

    const setImage = (payload) => {
        dispatch({type: SET_PROFILE_IMAGE, payload});
    }

    const setPage = () => {
        dispatch({type: SET_PROFILE_PAGE});
    }

    const unmount = () => {
        dispatch({type: UNMOUNT_PROFILE});
    }

    const fetchImages = async (page, size, order) => {

        if (state.last) return;

        dispatch({type: GET_PROFILE_START});

        axios.get(API_ENDPOINT + 'profile' + initHref(),
            {
                params: {
                    page,
                    size,
                    order,
                    discover: state.discover
                }
            })
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    dispatch({type: GET_PROFILE_SUCCESS, payload});
                }
            }).catch(error => {
            console.log(error)
        })
    }

    const like = async (uid) => {

        axios.post(API_ENDPOINT + `like/${uid}` + initHref())
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    dispatch({type: LIKE_SUCCESS, payload});
                }
            }).catch(error => {
            console.log(error)
        })
    }

    const updateDiscover = async (uid) => {

        axios.post(API_ENDPOINT + `discover/${uid}` + initHref())
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    dispatch({type: UPDATE_DISCOVER_SUCCESS, payload});
                }
            }).catch(error => {
            console.log(error)
        })
    }

    const setDeleteStatus = (payload) => {
        dispatch({type: DELETE_STATUS, payload});
    }

    const deleteImage = async (uid) => {

        const payload = "IN_PROGRESS"
        dispatch({type: DELETE_STATUS, payload});

        axios.delete(API_ENDPOINT + `delete/${uid}` + initHref())
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    const payload = uid
                    dispatch({type: DELETE_SUCCESS, payload});
                }
            }).catch(error => {
            const payload = handleError(error)
            dispatch({type: DELETE_STATUS, payload});
        })
    }

    const createUpscale = (uid) => {
        axios.post(API_ENDPOINT + 'upscale/' + uid + initHref()
        ).then(response => {
            const data = response.data
            const payload = data.data;
            dispatch({type: PROFILE_UPSCALE_START, payload});
        }).catch(error => {
            const payload = handleError(error)
            dispatch({type: PROFILE_UPSCALE_ERROR, payload});
        })
    }

    return (
        <ProfileContext.Provider value={{
            setPage,
            fetchImages,
            setImage,
            unmount,
            like,
            updateDiscover,
            changeDiscover,
            deleteImage,
            setDeleteStatus,
            createUpscale,
            user: state.user,
            isLoading: state.isLoading,
            page: state.page,
            images: state.images,
            image: state.image,
            last: state.last,
            discover: state.discover,
            statusDelete: state.statusDelete
        }}>
            {children}
        </ProfileContext.Provider>
    )
}