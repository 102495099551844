import React, {createContext, useContext, useReducer} from 'react';
import {paymentReducer} from "./paymentReducer";
import {API_ENDPOINT, initHref} from "../../utils/initHref";
import {
    GET_PAYMENT_URL_START, GET_PRODUCT_URL_ERROR, GET_PRODUCT_URL_SUCCESS,
    GET_PRODUCTS_ERROR,
    GET_PRODUCTS_START,
    GET_PRODUCTS_SUCCESS,
    SET_PRODUCTS
} from "../types";
import axios from "axios";
import {handleError} from "../../utils/handleError";
import {VkContext} from "../vk/VkState";
import {getResources} from "../../utils/languages";

export const PaymentContext = createContext(null);

export const PaymentState = ({children}) => {

    const initialState = {
        isLoading: false,
        urlIsLoading: false,
        products: [],
        product: null,
        paymentUrl: null,
        error: null,
        urlError: null,
        title: "",
        subtitle: ""
    };

    const [ state, dispatch ] = useReducer( paymentReducer, initialState );

    const getProducts = () => {
        dispatch({type: GET_PRODUCTS_START});

        axios.get(API_ENDPOINT + 'products' + initHref())
            .then(response => {
                if (response.status === 204) {
                    const payload = {
                        products: [],
                        title: getResources().title,
                        subtitle: getResources().subtitle
                    };
                    dispatch({type: GET_PRODUCTS_SUCCESS, payload});
                } else {
                    const data = response.data;
                    if (data.status === 'OK') {
                        const payload = data;
                        dispatch({type: GET_PRODUCTS_SUCCESS, payload});
                    }
                }
            }).catch(error => {
            const payload = handleError(error)
            dispatch({type: GET_PRODUCTS_ERROR, payload});

        })
    }

    const setProduct = (product) => {

        dispatch({type: SET_PRODUCTS, payload: product});

        getPaymentUrl(product.productId)

    }

    const getPaymentUrl = (productId) => {
        dispatch({type: GET_PAYMENT_URL_START});

        axios.put(API_ENDPOINT + 'platform/payment' + initHref(), {
            product_id:productId
        }).then(response => {
            const data = response.data;
            if (data.status === 'PaymentUI') {
                const payload = data.payment_url;

                dispatch({type: GET_PRODUCT_URL_SUCCESS, payload});
            }
        }).catch(error => {
            const payload = handleError(error)
            dispatch({type: GET_PRODUCT_URL_ERROR, payload});
        })
    }

    return (
        <PaymentContext.Provider value={{
            getProducts,
            setProduct,
            getPaymentUrl,
            isLoading: state.isLoading,
            urlIsLoading: state.urlIsLoading,
            products: state.products,
            product: state.product,
            paymentUrl: state.paymentUrl,
            error: state.error,
            urlError: state.urlError,
            title: state.title,
            subtitle: state.subtitle
        }}>
            {children}
        </PaymentContext.Provider>
    )
}