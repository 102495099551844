export const getResources = () => {

    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('vk_language');

    if (language !== null
        && language !== undefined) {
        if (language === 'ru') {
            return resources.ru
        }
    }

    const telegram = window.Telegram.WebApp;
    if (telegram.initData !== '') {
        if (telegram.initDataUnsafe.user.language_code === 'ru') {
            return resources.ru
        }
        return resources.en
    }

    return resources.en
}

const resources = {
    en: {
        title: 'AI Image Generator',
        subtitle: 'No products to purchase',
        create: 'Create',
        discover: 'Discover',
        profile: 'Profile',
        styleSelection: 'Choose a style',
        square: 'Square',
        stories: 'Stories',
        landscape: 'Landscape',
        portrait: 'Portrait',
        size: 'Size',
        hintInput: 'Enter a hint',
        hintLanguage: 'You can enter a hint in any language',
        europeanGirlCinematicLight: 'A girl of European descent, extremely detailed, colorful, cinematic light',
        createHint: 'How to create a hint?',
        add: 'Add',
        add46Styles: 'Add +46 additional styles',
        try: 'Try',
        reportIssue: 'Report an issue!',
        reportViolation: 'Report if you believe the image violates content posting rules on the platform',
        complain: 'Complain',
        goBack: 'Go back',
        balance: 'Balance',
        animate: 'Animate',
        share: 'Discover',
        hide: 'Hide',
        discoverSection: 'The image will appear in the Discover section',
        deleteImage: 'Delete image?',
        confirmDeleteImage: 'Are you sure you want to delete the image?',
        yes: 'Yes',
        uploadTimeMessage: 'This usually takes 10-15 seconds.\nYou can close it; the image will appear in your profile.',
        videoUploadTimeMessage: 'This usually takes 1-2 minutes.\nYou can close it; the video will appear in your profile.',
        generationProgress: 'Progress - ',
        preparingForGeneration: 'Preparing for generation...',
        repeat: 'Repeat',
        youAreBuying: 'You are buying ',
        pressPayToProceed: 'Press Pay to go to the payment system website.',
        pay: 'Pay',
        terms: 'Terms of service',
        policy: 'Privacy Policy',
        somethingWentWrong: 'Something went wrong, please try again later.',
        noDataForLoading: 'No data found for loading.',
        hintProcessingError: 'An error occurred while processing the hint. Please try again. If the error persists, contact the administrator.',
        imageProcessingError: 'An error occurred while processing the image. Please try again. If the error persists, contact the administrator.',
        hintNotCompliantError: 'Sorry, but the requested hint cannot be processed as it does not comply with the site\'s rules. Please choose another request.',
        requestThroughPlatform: 'Perform the request through Platform services',
        noPointsTopUpBalance: 'You don\'t have any Points. You can top up your balance in the Profile section.',
        styleUnavailablePurchaseInProfile: 'Style is unavailable. You can purchase it in the Profile section.',
        availableWithAdRemoval: 'Available with ad removal. More details in the Profile-Balance section.',
        generationRequestNotFoundError: 'Generation request not found. If the error persists, contact the administrator.',
        userNotFoundError: 'User not found. If the error persists, contact the administrator.',
        formatNotSupportedTryDifferentSize: 'Format not supported, try a different image size.',
        processingNotPossibleWaitForPrevious: 'Sorry, the request cannot be processed at the moment. Please wait for the previous generations to finish.',
        disableAds: 'Disable ads',
        watchAds: 'Watch ads',
        keepCreatingMasterpieces: 'Keep creating your masterpieces!',
        chooseOptionToContinue: 'Choose a convenient option to continue generating - either watch an ad or subscribe.',
        basedOn: ' Application AI Image Generator (no ads)',
        snackbar: 'Copy',
        dayLimitExceeded: 'Daily generation limit reached',
    },
    ru: {
        title: 'AI Image - Генерация изображений',
        subtitle: 'Нет продуктов для покупки',
        create: 'Создать',
        discover: 'Исследовать',
        profile: 'Профиль',
        styleSelection: 'Выбери стиль',
        square: 'Квадратное',
        stories: 'Сторис',
        landscape: 'Альбомное',
        portrait: 'Портретное',
        size: 'Размер',
        hintInput: 'Введите подсказку',
        hintLanguage: 'Подсказку можно вводить на любом языке',
        europeanGirlCinematicLight: 'Девушка европейской внешности, чрезвычайно детализированный, красочный, кинематографический свет',
        createHint: 'Как создать подсказку?',
        add: 'Добавить',
        add46Styles: 'Добавь + 46 дополнительных стилей',
        try: 'Попробовать',
        reportIssue: 'Пожаловаться!',
        reportViolation: 'Сообщите нам, если считаете, что картинка нарушает правила размещения контента на платформе',
        complain: 'Пожаловаться',
        goBack: 'Вернуться',
        balance: 'Баланс',
        animate: 'Анимировать',
        share: 'Поделиться',
        hide: 'Скрыть',
        discoverSection: 'Изображение появится в разделе "Исследовать"',
        deleteImage: 'Удалить изображение?',
        confirmDeleteImage: 'Вы действительно хотите удалить изображение?',
        yes: 'Да',
        uploadTimeMessage: 'Обычно это занимает 10-15 секунд.\nМожно закрыть, изображение появится в профиле.',
        videoUploadTimeMessage: 'Обычно это занимает 1-2 минуты.\nМожно закрыть, видео появится в профиле.',
        generationProgress: 'Загрузка - ',
        preparingForGeneration: 'Подготовка к генерации...',
        repeat: 'Повторить',
        youAreBuying: 'Вы покупаете ',
        pressPayToProceed: 'Нажмите "Оплатить" для перехода на сайт платежной системы.',
        pay: 'Оплатить',
        terms: 'Пользовательское соглашение',
        policy: 'Политика конфиденциальности',
        somethingWentWrong: 'Что-то пошло не так, попробуйте позже',
        noDataForLoading: 'Не найдено данных для загрузки',
        hintProcessingError: 'Произошла ошибка при обработке подсказки, попробуйте снова, если ошибка будет повторяться напишите администратору',
        imageProcessingError: 'Произошла ошибка при обработке изображения, попробуйте снова, если ошибка будет повторяться напишите администратору',
        hintNotCompliantError: 'Извините, но запрошенная подсказка не может быть обработана, так как не соответствует правилам сайта. Пожалуйста, выберите другой запрос.',
        requestThroughPlatform: 'Выполните запрос через сервисы Платформы',
        noPointsTopUpBalance: 'У вас нет Points. Пополнить баланс можно в разделе Профиль',
        styleUnavailablePurchaseInProfile: 'Стиль недоступен. Купить можно в разделе Профиль',
        availableWithAdRemoval: 'Доступно вместе с отказом от рекламы. Подробнее в разделе Профиль-Баланс',
        generationRequestNotFoundError: 'Запрос на генерацию не найден, если ошибка будет повторяться напишите администратору',
        userNotFoundError: 'Пользователь не найден, если ошибка будет повторяться напишите администратору',
        formatNotSupportedTryDifferentSize: 'Формат не поддерживается, попробуйте другой размер изображения',
        processingNotPossibleWaitForPrevious: 'Извините, в данный момент обработка запроса невозможна. Пожалуйста, дождитесь завершения предыдущих генераций',
        disableAds: 'Отключить рекламу',
        watchAds: 'Смотреть рекламу',
        keepCreatingMasterpieces: 'Продолжите создавать свои шедевры!',
        chooseOptionToContinue: 'Выберите удобный вариант для продолжения генерации - либо посмотрите рекламу, либо оформите подписку.',
        basedOn: ' Приложение AIIG - нейросеть для рисования (без рекламы)',
        snackbar: 'Скопировано',
        dayLimitExceeded: 'Достигнут дневной лимит генерации',
    }
};