import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button,
    ButtonGroup,
    FormItem,
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderClose,
    Platform, Popover,
    Textarea,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import {
    Icon20AddCircleFillBlue,
    Icon20CheckCircleFillGreen,
    Icon20DownloadOutline,
    Icon24Dismiss,
    Icon28PlaySpeedOutline,
    Icon28SyncOutline
} from "@vkontakte/icons";
import {ImageCard} from "../../components/ImageCard";
import {CreatorContext} from "../../context/creator/CreatorState";
import {useDimensions} from "../../utils/useDimensions";
import loader from '../../img/loader.json'
import {Player} from "@lottiefiles/react-lottie-player";
import classes from "../../components/css/AiCard.module.css";
import {downloadImage} from "../../utils/helpers";
import {VideoCard} from "../../components/VideoCard";
import {PRE_GENERATE, START_GENERATION} from "../../context/types";
import {getResources} from "../../utils/languages";
import axios from "axios";
import {API_ENDPOINT, initHref} from "../../utils/initHref";

const MODAL_CARD_ADS = 'ads_card';

export const CreateModalPage = ({id, modalBack, changeActiveModal}) => {

    const platform = usePlatform();

    const ref = useRef()
    const {width, height} = useDimensions(ref)

    const { isLoading, userIsPaid, uid, imageUid, image, setImage, createUpscale, prompt, setPrompt, style, resolution, createImage, createVideo, getStatus, status, genProgress, error, unmount } = useContext(CreatorContext)

    const intervalRef = useRef();
    const intervalContentRef = useRef();
    const statusRef = useRef(status);
    const playerRef = useRef();

    const { sizeX } = useAdaptivityConditionalRender();

    const [text, setText] = useState('');

    useEffect(() => {
        return () => {
            unmount(true)
        }
    }, [])

    useEffect(() => {
        if (error !== null ||
            statusRef.current === 'IMAGE_ERROR' ||
            statusRef.current === 'VIDEO_ERROR' ||
            statusRef.current === "IMAGE_CLOSED" ||
            statusRef.current === "VIDEO_CLOSED" ) {

            pauseAnimation();

        } else {
            playAnimation();
        }
    }, [error, isLoading]);

    useEffect(() => {
        statusRef.current = status;
        if (status === 'IMAGE_CLOSED') {
            getImageContent(imageUid)
            setText(prompt)
        }
    }, [status]);

    useEffect(() => {
        if (uid !== null && statusRef.current !== null) {
            const fetchWithDelay = () => {
                if (statusRef.current === 'IMAGE_ERROR' ||
                    statusRef.current === 'VIDEO_ERROR' ||
                    statusRef.current === "IMAGE_CLOSED" ||
                    statusRef.current === "VIDEO_CLOSED") {
                    clearTimeout(intervalRef.current);
                } else {
                    getStatus();
                    intervalRef.current = setTimeout(fetchWithDelay, 3000);
                }
            };

            fetchWithDelay();

        }
        return () => {
            clearTimeout(intervalRef.current);
        };
    }, [uid])

    useEffect(() => {
        if (image !== null && image.upscale !== undefined
            && (image.upscale_status === 'UPSCALE_QUEUE' || image.upscale_status === 'UPSCALE_TAKEN')) {

            const fetchWithDelay = () => {
                intervalContentRef.current = setTimeout(getImageContent, 6000);
            }

            fetchWithDelay();

        } else {
            clearTimeout(intervalContentRef.current);
        }
        return () => {
            clearTimeout(intervalContentRef.current);
        };
    }, [image]);

    const getImageContent = () => {
        axios.get(API_ENDPOINT + 'image/' + imageUid + initHref(), {
            params: {
                resolution: 'content'
            }
        }).then(response => {
            const data = response.data
            if (data.status === 'OK') {
                const image = data.request
                setImage(image)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const onCreate = () => {
        statusRef.current = null
        if (userIsPaid || platform === Platform.IOS) {
            createImage();
        } else {
            changeActiveModal(MODAL_CARD_ADS)
        }
    }

    const animate = () => {
        if (imageUid !== null) {
            createVideo(imageUid);
        }
    }

    const playAnimation = () => {
        if (playerRef.current) {
            playerRef.current.play();
        }
    };

    const pauseAnimation = () => {
        if (playerRef.current) {
            playerRef.current.pause();
        }
    };

    const setDisclaimer = () => {
        if (status === 'QUEUE' ||
            status === 'PROMPT_TAKEN' ||
            status === 'PROMPT_CLOSED' ||
            status === 'IMAGE_TAKEN' ||
            statusRef.current === 'QUEUE' ||
            statusRef.current === 'PROMPT_TAKEN' ||
            statusRef.current === 'PROMPT_CLOSED' ||
            statusRef.current === 'IMAGE_TAKEN'
        ) {
            return getResources().uploadTimeMessage
        } else if (
            status === 'VIDEO_TAKEN' ||
            statusRef.current === 'VIDEO_TAKEN'
        ) {
            return genProgress !== null && genProgress !== '0' ? getResources().generationProgress + genProgress + '%' : getResources().videoUploadTimeMessage;
        } else if (
            status === 'PRE_GENERATE' ||
            status === 'START_GENERATION' ||
            statusRef.current === 'PRE_GENERATE' ||
            statusRef.current === 'START_GENERATION'
        ) {
            return getResources().preparingForGeneration
        } else {
            return null
        }
    }

    const addButtonGroup = () => {
        if (isLoading) {
            return null;
        }
        if (statusRef.current === 'IMAGE_CLOSED' || statusRef.current === 'IMAGE_ERROR') {
            return  (
                <ButtonGroup style={{marginBottom: 8}} mode="horizontal" gap="m" stretched>
                    { (resolution === 'Stories' || resolution === 'Portrait' || platform === Platform.IOS) ? null :
                        <Button
                            style={{marginBottom: 6}}
                            before={<Icon28PlaySpeedOutline/>}
                            size="l"
                            align="center"
                            appearance="accent"
                            mode="outline"
                            stretched
                            disabled={ prompt === '' || prompt !== text }
                            onClick={() => animate()}>
                            {getResources().animate}
                        </Button>
                    }
                    <Button
                        before={<Icon28SyncOutline/>}
                        size="l"
                        appearance="accent"
                        mode="outline"
                        stretched={resolution === 'Stories' || resolution === 'Portrait' || platform === Platform.IOS}
                        disabled={ prompt === '' }
                        onClick={ () => onCreate() }>
                        { resolution === 'Stories' || resolution === 'Portrait' || platform === Platform.IOS ?
                            getResources().repeat : null
                        }
                    </Button>
                </ButtonGroup>
            )
        }
        if (statusRef.current === 'VIDEO_CLOSED' || statusRef.current === 'VIDEO_ERROR') {
            return (
                <ButtonGroup style={{marginBottom: 8}} mode="horizontal" gap="m" stretched>
                    <Button
                        before={<Icon28PlaySpeedOutline/>}
                        size="l"
                        appearance="accent"
                        mode="outline"
                        stretched
                        disabled={prompt === ''}
                        onClick={ () => animate() }>
                        {getResources().repeat}
                    </Button>
                </ButtonGroup>
            )
        }
    }

    const PopoverWithTriggerClick = () => {
        return (
            <Popover
                trigger="click"
                id="menupopup"
                role="dialog"
                aria-labelledby="menubutton"
                content={({onClose}) => (
                    <div>
                        <Button
                            role="menuitem"
                            after={<Icon20CheckCircleFillGreen/>}
                            align={'center'}
                            appearance={'accent'}
                            stretched={true}
                            rounded={false}
                            mode={'outline'}
                            disabled={false}
                            size={'m'}
                            loading={false}
                            onClick={() => {downloadImage(image.uid)}}>
                            X1
                        </Button>
                        <Button
                            role="menuitem"
                            after={image.upscale === undefined ? <Icon20AddCircleFillBlue/> : <Icon20CheckCircleFillGreen/>}
                            align={'center'}
                            appearance={'accent'}
                            stretched={true}
                            rounded={false}
                            mode={'outline'}
                            disabled={!userIsPaid}
                            size={'m'}
                            loading={image.upscale !== undefined
                                && (image.upscale_status === 'UPSCALE_QUEUE' || image.upscale_status === 'UPSCALE_TAKEN')}
                            onClick={() => {image.upscale_status !== undefined && image.upscale_status === 'UPSCALE_CLOSED' ? downloadImage(image.uid, true) : createUpscale(image.uid)}}>
                            X2
                        </Button>
                    </div>
                )}
            >
                <button className={classes.block_right} id="menubutton" aria-controls="menupopup">
                    <Icon20DownloadOutline/>
                </button>
            </Popover>
        );
    };

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            hideCloseButton={platform === Platform.IOS}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === Platform.ANDROID && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
                        )
                    }
                    after={
                        platform === Platform.IOS && (
                            <PanelHeaderButton onClick={modalBack}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                </ModalPageHeader>
            }
        >
            <Group getRootRef={ref} style={{marginLeft: 16, marginRight:16}}>
                {!isLoading && uid !== null && (status === 'IMAGE_CLOSED' || status === 'VIDEO_CLOSED') ?
                    <>
                        { status === 'IMAGE_CLOSED' &&
                            <ImageCard uid={uid} style={style && style} prompt={prompt}/>
                        }
                        { status === 'VIDEO_CLOSED' &&
                            <VideoCard uid={uid}/>
                        }
                        { status === 'IMAGE_CLOSED' &&
                            <div className={classes.like_container}>
                                <div className={classes.block_container}/>
                                <button className={classes.block_right}>
                                    { image !== null ?
                                        <PopoverWithTriggerClick/>
                                        : null
                                    }
                                </button>
                            </div>
                        }
                    </>
                    :
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <Player
                            ref={playerRef}
                            autoplay
                            loop
                            speed={2}
                            src={loader}/>
                        <div className={`${classes.description} ${error !== null ? classes.description_error : ''}`}>
                            { error !== null ? error :
                                setDisclaimer()
                            }
                        </div>
                    </div>
                }
                <div style={{marginTop: 24, marginBottom: 26}}>
                    <FormItem noPadding>
                        <Textarea
                            value={prompt}
                            disabled={isLoading || status === 'VIDEO_CLOSED'}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder={getResources().europeanGirlCinematicLight}
                        />
                    </FormItem>
                </div>
                {addButtonGroup()}
            </Group>
        </ModalPage>
    )
}



