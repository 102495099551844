import React, {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Cell,
    Epic,
    Group,
    Panel,
    PanelHeader,
    Platform,
    Snackbar,
    SplitCol,
    SplitLayout,
    Tabbar,
    TabbarItem,
    useAdaptivityConditionalRender,
    usePlatform,
    View
} from "@vkontakte/vkui";
import {
    Icon28CompassOutline,
    Icon28MagicWandOutline,
    Icon28UserSquareOutline,
    Icon48StarsCircleFillViolet
} from "@vkontakte/icons";
import {CreatePanel} from "./panels/CreatePanel";
import {AiPanelHeader} from "../components/AiPanelHeader";
import {DiscoverPanel} from "./panels/DiscoverPanel";
import {AiBaseModal} from "./modals/AiBaseModal";
import {ProfilePanel} from "./panels/ProfilePanel";
import {getResources} from "../utils/languages";
import {VkContext} from "../context/vk/VkState";

const MODAL_PAGE_CREATE = 'create_image_screen';
const MODAL_PAGE_DISCOVER = 'discover_image_screen';
const MODAL_PAGE_PROFILE = 'profile_image_screen';
const MODAL_PAGE_PAYMENT = 'payment_page';
const MODAL_PAGE_DESCRIPTION = 'description_page';
const MODAL_PAGE_DESCRIPTION_EN = 'description_page_en';
const MODAL_CARD_ADS = 'ads_card';

export const MainRoot = () => {

    const platform = usePlatform();

    const { viewWidth } = useAdaptivityConditionalRender();
    const [activeStory, setActiveStory] = useState('create');
    const activeStoryStyles = {
        backgroundColor: 'var(--vkui--color_background_secondary)',
        borderRadius: 8,
    };
    const onStoryChange = (e) => setActiveStory(e.currentTarget.dataset.story);
    const isVKCOM = platform !== Platform.VKCOM;
    const {vkUser} = useContext(VkContext)

    const [activeModal, setActiveModal] = useState(null);
    const [snackbar, setSnackbar] = useState(null);
    const [alert, setAlert] = React.useState(null);


    const changeActiveModal = (activeModal) => {
        activeModal = activeModal || null;
        setActiveModal(activeModal);
    };

    const modalBack = () => {
        changeActiveModal(null);
    };

    useEffect(() => {
        if (alert === null) {
            const telegram = window.Telegram.WebApp;
            if (telegram.initData === '') {
                if (vkUser === 'VKUndefined') {
                    openAlert()
                }
            }
        }
    },[alert, vkUser])

    const closeAlert = () => {
        setAlert(null);
    };

    const openAlert = () => {
        setAlert(
            <Alert
                actionsLayout="vertical"
                onClose={closeAlert}
                header="Предупреждение!"
                text="Выполните запрос через сервисы Платформы"
            />,
        );
    };

    const openBaseWithAction = () => {
        if (snackbar) return;
        setSnackbar(
            <Snackbar
                layout="vertical"
                onClose={() => setSnackbar(null)}
                action={getResources().add}
                onActionClick={() => changeActiveModal(MODAL_PAGE_PAYMENT)}
                before={
                    <Icon48StarsCircleFillViolet fill="#fff" width={32} height={32} />
                }
            >
                {getResources().add46Styles}
            </Snackbar>
        );
    };

    return (
        <SplitLayout
            header={isVKCOM && <PanelHeader delimiter='none' />}
            style={{ justifyContent: 'center' }}
            modal={<AiBaseModal activeModal={activeModal} modalBack={modalBack} changeActiveModal={changeActiveModal}/>}
            popout={alert}
        >
            {viewWidth.tabletPlus && (
                <SplitCol className={viewWidth.tabletPlus.className} fixed width={280} maxWidth={280}>
                    <Panel>
                        {isVKCOM && <PanelHeader />}
                        <Group>
                            <Cell
                                disabled={activeStory === 'create'}
                                style={activeStory === 'create' ? activeStoryStyles : undefined}
                                data-story="create"
                                onClick={onStoryChange}
                                before={<Icon28MagicWandOutline />}
                            >
                                Создать
                            </Cell>
                            <Cell
                                disabled={activeStory === 'discover'}
                                style={activeStory === 'discover' ? activeStoryStyles : undefined}
                                data-story="discover"
                                onClick={onStoryChange}
                                before={<Icon28CompassOutline />}
                            >
                                Исследовать
                            </Cell>
                            <Cell
                                disabled={activeStory === 'profile'}
                                style={activeStory === 'profile' ? activeStoryStyles : undefined}
                                data-story="profile"
                                onClick={onStoryChange}
                                before={<Icon28UserSquareOutline />}
                            >
                                Профиль
                            </Cell>
                        </Group>
                    </Panel>
                </SplitCol>
            )}

            <SplitCol width="100%" maxWidth="560px" stretchedOnMobile autoSpaced>
                <Epic
                    activeStory={activeStory}
                    tabbar={
                        viewWidth.tabletMinus && (
                            <Tabbar className={viewWidth.tabletMinus.className}>
                                <TabbarItem
                                    onClick={onStoryChange}
                                    selected={activeStory === 'create'}
                                    data-story="create"
                                    text={getResources().create}
                                >
                                    <Icon28MagicWandOutline />
                                </TabbarItem>
                                <TabbarItem
                                    onClick={onStoryChange}
                                    selected={activeStory === 'discover'}
                                    data-story="discover"
                                    text={getResources().discover}
                                >
                                    <Icon28CompassOutline />
                                </TabbarItem>
                                <TabbarItem
                                    onClick={onStoryChange}
                                    selected={activeStory === 'profile'}
                                    data-story="profile"
                                    text={getResources().profile}
                                >
                                    <Icon28UserSquareOutline />
                                </TabbarItem>
                            </Tabbar>

                        )
                    }
                >
                    <View id="create" activePanel="create">
                        <Panel id="create">
                            <AiPanelHeader title={getResources().styleSelection}/>
                            <CreatePanel
                                openBaseWithAction={openBaseWithAction}
                                openModal={() => changeActiveModal(MODAL_PAGE_CREATE)}
                                descriptionOpen={() => changeActiveModal(MODAL_PAGE_DESCRIPTION)}
                                descriptionOpenEn={() => changeActiveModal(MODAL_PAGE_DESCRIPTION_EN)}
                                openAds={() => changeActiveModal(MODAL_CARD_ADS)}
                            />
                            {snackbar}
                        </Panel>
                    </View>
                    <View id="discover" activePanel="discover">
                        <Panel id="discover">
                            <AiPanelHeader title={getResources().discover} />
                            <DiscoverPanel openModal={() => changeActiveModal(MODAL_PAGE_DISCOVER)}/>
                        </Panel>
                    </View>
                    <View id="profile" activePanel="profile">
                        <Panel id="profile">
                            <AiPanelHeader title={getResources().profile}/>
                            <ProfilePanel
                                openModal={() => changeActiveModal(MODAL_PAGE_PROFILE)}
                                openPaymentCard={() => changeActiveModal(MODAL_PAGE_PAYMENT)}
                            />
                        </Panel>
                    </View>
                </Epic>
            </SplitCol>
        </SplitLayout>
    )
}