import React from 'react';
import {VkState} from "./vk/VkState";
import {ProfileState} from "./profile/ProfileState";
import {DiscoverState} from "./discover/DiscoverState";
import {CreatorState} from "./creator/CreatorState";
import {CacheState} from "./cache/CacheState";
import {PaymentState} from "./payment/PaymentState";

export const ContextProvider = ({children}) => {

    return (
        <VkState>
            <CacheState>
                <PaymentState>
                    <ProfileState>
                        <CreatorState>
                            <DiscoverState>
                                {children}
                            </DiscoverState>
                        </CreatorState>
                    </ProfileState>
                </PaymentState>
            </CacheState>
        </VkState>
    )
}