import React from 'react';
import {
    Caption,
    Div,
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderClose,
    Paragraph,
    Platform,
    Title,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import {Icon24Dismiss} from "@vkontakte/icons";

export const DescriptionModalPage = ({id, modalBack}) => {

    const platform = usePlatform();
    const { sizeX } = useAdaptivityConditionalRender();

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            size='s'
            hideCloseButton={platform === Platform.IOS}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === Platform.ANDROID && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
                        )
                    }
                    after={
                        platform === Platform.IOS && (
                            <PanelHeaderButton onClick={modalBack}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                    Как создать подсказку?
                </ModalPageHeader>
            }>
            <Group>
                <Div>
                    Промпт - это инструкция или запрос, который вы отправляете AI Image, чтобы получить определенный результат. Понимание того, как правильно формулировать промпты, может значительно улучшить ваш опыт взаимодействия с приложением. В этой статье мы рассмотрим, как создать эффективный промпт для AI Image с конкретными примерами.
                </Div>
                <Div>
                    <Title Component="h1">
                        Ясность и конкретика
                    </Title>
                </Div>
                <Div>
                    Одним из ключевых моментов при создании промпта является его ясность и конкретика.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Плохой пример:</span> "Сделай изображение."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Хороший пример:</span> "Создай изображение солнечного заката на пляже с пальмами."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/67c431f3fa0f19a244036.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Создай изображение солнечного заката на пляже с пальмами</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Используйте стили
                    </Title>
                </Div>
                <Div>
                    Стили позволяют создавать изображения для любых целей.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Стиль:</span> Impressionism
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/e3aa45bc1bbf9159c1627.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Создай изображение солнечного заката на пляже с пальмами.</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Ограничьте количество информации
                    </Title>
                </Div>
                <Div>
                    Сжатое и ясное описание поможет быстрее и точнее выполнить ваш запрос.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Плохой пример:</span> "Мне нужно изображение, где будет много цветов, таких как розы, тюльпаны, орхидеи, и они все будут расположены вокруг пруда, на котором плавают лебеди, и вокруг пруда будут бабочки..."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Хороший пример:</span> "Создай изображение пруда с лебедями и окружающими его цветами."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/e25666ad7af368f9f8447.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Создай изображение пруда с лебедями и окружающими его цветами</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Избегайте двусмысленности
                    </Title>
                </Div>
                <Div>
                    Чем однозначнее ваш запрос, тем точнее будет результат.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Плохой пример:</span> "Сделай что-нибудь красивое."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Хороший пример:</span> "Создай изображение горного пейзажа на закате."
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/d5a99ad52865667354759.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Создай изображение горного пейзажа на закате</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Экспериментируйте
                    </Title>
                </Div>
                <Div>
                    Не бойтесь экспериментировать с разными формулировками, чтобы получить лучший результат.
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Плохой пример:</span> "Мне нужна картинка девушки."
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Хороший пример:</span> "Темный кадр, фотография симпатичной женщины 24 года, идеальные глаза, натуральная кожа, смотрит на зрителя, кинематографический кадр"
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/5d72ab34caae0fbf66606.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Темный кадр, фотография симпатичной женщины 24 года, идеальные глаза, натуральная кожа, смотрит на зрителя, кинематографический кадр</Caption>
                </Div>
                <Div>
                    <Title Component="h1">
                        Еще примеры:
                    </Title>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Стиль:</span> Greg Tocchini
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/2dea58a1b001651006584.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Masterpiece,best quality,extremely detailed,closeup full body view, strikingly beautiful young icelandic woman in a cluttered bedroom,detailed face,eyes closed,frowning,vivid dyed ultraviolet hair,relaxed,under lit,narrow waist,wide hips</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Стиль:</span> Charcoal Sketch
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/72c3e0a990582b32c04cf.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Disney Tinkerbell, full body</Caption>
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/8275e0cc90f09cc93e4c9.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">A messy, chaotic artist's workshop, inside is a cute robot painting a picture. The picture is of another robot</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Стиль:</span> Watercolor
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/679e93f1856d299996e3f.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">Back side view of a girl sitting on a open window, looking sideway, blue striped shirt, pink skirt, inside a house, sunset, blue sky</Caption>
                </Div>
                <Div>
                    <span style={{fontStyle: 'italic'}}>Стиль:</span> Anime
                </Div>
                <Div>
                    <img width='100%' src='https://aiimagecreator.ru/images/f3e31e4fa886c13ce9b7f.png' alt='' />
                    <Caption style={{textAlign: 'center', fontStyle: 'italic'}} level="1">masterpiece, best quality, highres, intricate detail, 1girl, light brown hair, brown eyes, school uniform, parted lips, high quality eyes, high quality iris, best quality, detailed, quality, lifelike, precise, vibrant</Caption>
                </Div>
                <Div style={{textAlign: 'center'}}>
                    <Paragraph>
                        Не упустите шанс раскрыть весь потенциал искусственного интеллекта в создании визуального контента. Попробуйте AI Image прямо сейчас и убедитесь в его возможностях на практике!
                    </Paragraph>
                </Div>
            </Group>

        </ModalPage>
    )
}