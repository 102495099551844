import React, {useContext, useEffect, useRef} from "react";
import {Group, Spinner} from "@vkontakte/vkui";
import {useDimensions} from "../../utils/useDimensions";
import {DiscoverContext} from "../../context/discover/DiscoverState";
import {ThumbnailCards} from "../../components/ThumbnailCards";

export const DiscoverPanel = ({openModal}) => {

    const ref = useRef()
    const {width, cardWidth} = useDimensions(ref)

    const {isLoading, setImage, images, fetchImages, page, setPage, last, like, unmount} = useContext(DiscoverContext)

    const selectImage = (image) => {
        setImage(image)
        openModal()
    }

    useEffect(() => {
        fetchImages(page, 10, 'desc');
    }, [page])

    useEffect(() => {

        const widthCount = Math.floor(width / cardWidth);
        const heightCount = Math.floor(window.innerHeight / cardWidth);
        const cardCount = widthCount * heightCount;

        if (images.length < cardCount && !isLoading && !last) {
            setPage();
        }

    }, [images]);

    useEffect(() => {
        return () => {
            unmount()
        }
    }, [])


    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight
            const currentHeight = e.target.documentElement.scrollTop + window.innerHeight
            if (currentHeight + 1 >=  scrollHeight && !isLoading && !last) {
                setPage();
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading]);

    return (
        <div ref={ref}>
            <Group>
                <ThumbnailCards
                    images={images}
                    width={cardWidth}
                    height={cardWidth}
                    onClick={(image) => selectImage(image)}
                    like={like}
                />
            </Group>
            {isLoading &&  <Spinner size="regular" style={{ margin: '20px 0' }} />}
        </div>
    );
}