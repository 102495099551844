import React, {useEffect, useState} from "react";
import classes from './css/AiCard.module.css'
import axios from "axios";
import {API_ENDPOINT, initHref} from "../utils/initHref";
import {Spinner, useModalRootContext} from "@vkontakte/vkui";
import refresh from '../img/refresh_outline_28.svg'

export const ImageCard = ({ uid, style, prompt }) => {

    const [src, setSrc] = useState(null);
    const { updateModalHeight } = useModalRootContext();

    useEffect(() => updateModalHeight, [src])

    useEffect(() => {
        axios.get(API_ENDPOINT + 'image/' + uid + initHref(), {
            params: {
                resolution: 'full'
            }
        }).then(response => {
            const data = response.data
            if (data.status === 'OK') {
                const src = data.data
                setSrc(src);
            }
        }).catch(error => {
            console.log(error)
        });
    },[])

    return (
        <div className={classes.container}>
            { src !== null ?
                <img
                    style={{borderRadius: 15}}
                    alt={prompt}
                    width='100%'
                    height='100%'
                    src={src}
                    onError={(e) => {
                        e.target.src=refresh
                    }}
                />
                :
                <Spinner size="medium"/>
            }
            { (src !== null && style !== null) ?
                <div className={classes.bottomLeft}>
                    {style}
                </div>
                : null
            }
        </div>

    )
}