import React from "react";
import { createRoot } from "react-dom/client";
import bridge from "@vkontakte/vk-bridge";
import { App } from "./App";
import '@vkontakte/vkui/dist/vkui.css';
import './themes.css'

// Init VK  Mini App
bridge.send("VKWebAppInit");

// Init Telegram  Mini App
const telegram = window.Telegram.WebApp

if (telegram.initData !== '') {
    const scheme = telegram.colorScheme;
    let schemeAttribute = document.createAttribute('scheme');
    schemeAttribute.value = scheme === 'dark' ? 'space_gray' : 'bright_light';
    document.body.attributes.setNamedItem(schemeAttribute);
}

createRoot(document.getElementById("root")).render(<App/>);