import { useMemo, useSyncExternalStore } from "react"

function subscribe(callback) {
    window.addEventListener("resize", callback)
    return () => {
        window.removeEventListener("resize", callback)
    }
}

function useDimensions(ref) {
    const dimensions = useSyncExternalStore(
        subscribe,
        () => JSON.stringify({
            width: ref.current?.offsetWidth ?? 0,
            height: ref.current?.offsetHeight ?? 0,
            cardWidth: cardWidth(ref.current?.offsetWidth ?? 0)
        })
    )
    return useMemo(() => JSON.parse(dimensions), [dimensions])
}

const cardWidth = (width) => {
    if (width <= 360) {
        return 150;
    }
    if (width >= 560){
        return 160;
    }
    return (width-32-24)/2;
}

export { useDimensions }