import React, {useContext, useEffect} from 'react';
import {Button, ButtonGroup, ModalCard, Spacing} from "@vkontakte/vkui";
import {Icon56DeleteOutline} from '@vkontakte/icons';
import {ProfileContext} from "../../context/profile/ProfileState";
import {getResources} from "../../utils/languages";

const MODAL_PAGE_PROFILE = 'profile_image_screen';

export const DeleteModalCard = ({id, modalBack, changeActiveModal}) => {

    const { image, deleteImage, statusDelete } = useContext(ProfileContext)

    const click = () => {
        deleteImage(image.uid)
    }

    useEffect(() => {
        if (statusDelete === 'SUCCESS') {
            modalBack()
        }
    }, [statusDelete])

    return (
        <ModalCard
            id={id}
            onClose={modalBack}
            icon={<Icon56DeleteOutline />}
            header={getResources().deleteImage}
            subheader={getResources().confirmDeleteImage}
            actions={
                <React.Fragment>
                    <Spacing size={16} />
                    <ButtonGroup size="l" mode="vertical" stretched>
                        <Button
                            key="complaint"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => click()}
                        >
                            {getResources().yes}
                        </Button>
                        <Button
                            key="back"
                            size="l"
                            mode="secondary"
                            stretched
                            onClick={() => changeActiveModal(MODAL_PAGE_PROFILE)}
                        >
                            {getResources().goBack}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        ></ModalCard>
    )
}



