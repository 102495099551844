import React, {createContext, useReducer} from 'react';
import {creatorReducer} from "./creatorReducer";
import axios from "axios";
import {API_ENDPOINT, initHref} from "../../utils/initHref";
import {
    CREATOR_UPSCALE_ERROR,
    CREATOR_UPSCALE_START,
    GEN_IMAGE_COUNTER,
    GENERATION_ERROR,
    GENERATION_IN_PROGRESS,
    GENERATION_SUCCESS,
    GET_STYLES,
    PRE_GENERATE,
    SET_CREATOR_IMAGE,
    SET_IMAGE_UID,
    SET_PROMPT,
    SET_RESOLUTION,
    SET_STYLE,
    SET_USER_ADS,
    START_GENERATION,
    UNMOUNT_CREATE,
    UNMOUNT_CREATE_NOT_CHANGE_LOADING
} from "../types";
import {handleError} from "../../utils/handleError";
import bridge from "@vkontakte/vk-bridge";

export const CreatorContext = createContext();

const telegram = window.Telegram.WebApp

export const CreatorState = ({ children }) => {

    const initialState = {
        prompt: '',
        userIsPaid: false,
        styles: [],
        style: null,
        isLoading: false,
        status: null,
        error: null,
        uid: null,
        imageUid: null,
        image: null,
        genCounter: 0,
        resolution: null,
        genProgress: null
    };

    const [state, dispatch] = useReducer(creatorReducer, initialState);

    const setResolution = (payload) => {
        dispatch({type: SET_RESOLUTION, payload});
    }

    const setPrompt = (payload) => {
        dispatch({type: SET_PROMPT, payload});
    }

    const setStyle = (payload) => {
        dispatch({type: SET_STYLE, payload});
    }

    const setImage = (payload) => {
        dispatch({type: SET_CREATOR_IMAGE, payload});
    }

    const unmount = (changeLoader) => {
        if (changeLoader) {
            dispatch({type: UNMOUNT_CREATE});
        } else {
            dispatch({type: UNMOUNT_CREATE_NOT_CHANGE_LOADING});
        }
    }

    const setImageUid = (image) => {
        if (image.status === 'IMAGE_CLOSED') {
            const payload = image.uid
            dispatch({type: SET_IMAGE_UID, payload});
        }
    }

    const getStyles = () => {

        loadGenCounter()

        axios.get(API_ENDPOINT + 'styles' + initHref()
        ).then(response => {
            const data = response.data;
            if (data.status === 'HIDE_ADS' || data.status === 'OK') {
                let userIsPaid = false;
                if (data.status === 'OK') {
                    if (telegram.initData !== '') {
                        userIsPaid = true;
                    }
                    showShowBannerAds()
                } else if (data.status === 'HIDE_ADS') {
                    userIsPaid = true;
                    hideBannerAds()
                }
                dispatch({ type: SET_USER_ADS, userIsPaid })
                const payload = data.styles;
                payload.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1)
                dispatch({type: GET_STYLES, payload});
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const createVideo = (uid) => {

        dispatch({type: PRE_GENERATE});

        axios.post(API_ENDPOINT + 'video/' + uid + initHref()
        ).then(response => {
            const data = response.data
            const payload = data.data;
            dispatch({type: START_GENERATION, payload});

        }).catch(error => {
            const payload = handleError(error)
            dispatch({type: GENERATION_ERROR, payload});
        })
    }

    const createImage = (prompt, style, resolution) => {

        dispatch({type: PRE_GENERATE});

        const body = {
            prompt: prompt !== undefined ? prompt : state.prompt,
            style: style !== undefined ? style : state.style && state.style,
            resolution: resolution !== undefined ? resolution : state.resolution && state.resolution
        };

        axios.post(API_ENDPOINT + 'text2img' + initHref(),
            body
        ).then(response => {

            const data = response.data

            if (data.status === 'HIDE_ADS' || data.status === 'OK') {

                if (state.genCounter === 4) {
                    addToFavorite()
                } else if (state.genCounter === 12) {
                    addToHome()
                } else if (state.genCounter === 20) {
                    recommend()
                } else if (data.status === 'OK') {
                    let userIsPaid = false;
                    if (telegram.initData !== '') {
                        userIsPaid = true;
                    }
                    showNativeAds();
                    dispatch({ type: SET_USER_ADS, userIsPaid })
                } else if (data.status === 'HIDE_ADS') {
                    let userIsPaid = true;
                    dispatch({ type: SET_USER_ADS, userIsPaid })
                }

                setGenCounter(state.genCounter + 1);

                const payload = data.data;
                dispatch({type: START_GENERATION, payload});
            }

        }).catch(error => {
            const payload = handleError(error)
            dispatch({type: GENERATION_ERROR, payload});
        })
    };

    const getStatus = () => {

        axios.get(API_ENDPOINT + 'status/' + state.uid + initHref())
            .then(response => {
                const payload = response.data;
                const status = payload.status;
                if (status === 'VIDEO_CLOSED' || status === 'IMAGE_CLOSED') {
                    dispatch({type: GENERATION_SUCCESS, payload});
                } else {
                    dispatch({type: GENERATION_IN_PROGRESS, payload});
                }
            }).catch(error => {
            const payload = handleError(error)
            dispatch({type: GENERATION_ERROR, payload});
        })
    }

    const createUpscale = (uid) => {
        axios.post(API_ENDPOINT + 'upscale/' + uid + initHref()
        ).then(response => {
            const data = response.data
            const payload = data.data;
            dispatch({type: CREATOR_UPSCALE_START, payload});
        }).catch(error => {
            const payload = handleError(error)
            dispatch({type: CREATOR_UPSCALE_ERROR, payload});
        })
    }

    const hideBannerAds = () => {
        bridge.send('VKWebAppHideBannerAd').then((data) => {
            if (data.result) {
                console.log('Banner advertisement hidden');
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    const showShowBannerAds = () => {
        bridge.send('VKWebAppCheckBannerAd')
            .then((data) => {
                if (!data.result) {
                    bridge.send('VKWebAppShowBannerAd', {banner_location: 'bottom'})
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const showNativeAds = () => {
        bridge.send('VKWebAppShowNativeAds', {
            ad_format: 'reward',
            use_waterfall: true
        }).then((data) => {
            if (data.result)
                console.log('Advertisement shown');
            else
                console.log('Error displaying');
        }).catch((error) => { console.log(error); /* Ошибка */ });
    }

    const loadGenCounter = () => {
        bridge.send('VKWebAppStorageGet', {
            keys: [
                'genCounter'
            ]})
            .then((data) => {
                if (data.keys) {
                    if (data.keys.length > 0 && data.keys[0].value === undefined) {
                        setGenCounter(0)
                    } else {
                        setGenCounter(parseInt(data.keys[0].value))
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const setGenCounter = (value) => {
        bridge.send('VKWebAppStorageSet', {
            key: 'genCounter',
            value: value.toString()
        })
            .then((data) => {
                if (data.result) {
                    const payload = value
                    dispatch({type: GEN_IMAGE_COUNTER, payload});
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const addToFavorite = () => {
        bridge.send('VKWebAppAddToFavorites')
    }

    const addToHome = () => {
        bridge.send('VKWebAppAddToHomeScreenInfo')
            .then((data) => {
                if (!data.is_added_to_home_screen && data.is_feature_supported) {
                    bridge.send('VKWebAppAddToHomeScreen')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const recommend = () => {
        bridge.send('VKWebAppRecommend').then((data) => {
            if (data.result) {
                console.log(data.result)
            }
        })
            .catch((error) => {
                // Ошибка
                console.log(error);
            });
    }

    return (
        <CreatorContext.Provider value={{
            getStyles,
            setPrompt,
            setStyle,
            createImage,
            getStatus,
            unmount,
            setResolution,
            createVideo,
            setImageUid,
            setImage,
            createUpscale,
            styles: state.styles,
            userIsPaid: state.userIsPaid,
            prompt: state.prompt,
            style: state.style,
            isLoading: state.isLoading,
            status: state.status,
            error: state.error,
            uid: state.uid,
            imageUid: state.imageUid,
            image: state.image,
            genCounter: state.genCounter,
            resolution: state.resolution,
            genProgress: state.genProgress
        }}>
            {children}
        </CreatorContext.Provider>
    );
}