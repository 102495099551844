import {getResources} from "./languages";

export const handleError = (error) => {

    let payload = getResources().somethingWentWrong;

    if (error.response !== undefined) {
        const status = error.response.status;
        const data = error.response.data
        switch (status) {
            case 204:
                payload = getResources().noDataForLoading
                break
            case 400:
                if (data.data !== undefined) {
                    if (data.data === 'PROMPT_ERROR') {
                        payload = getResources().hintProcessingError
                    } else if (data.data === 'IMAGE_ERROR') {
                        payload = getResources().imageProcessingError
                    } else if (data.data === "IMAGE_NSFW") {
                        payload = getResources().hintNotCompliantError
                    }
                }
                break;
            case 401:
                payload = getResources().requestThroughPlatform
                break;
            case 402:
                if (data.data !== undefined) {
                    if (data.data === 'Not enough points') {
                        payload = getResources().noPointsTopUpBalance;
                    } else if (data.data === 'Not enough style') {
                        payload = getResources().styleUnavailablePurchaseInProfile;
                    } else if (data.data === 'Not enough subscription') {
                        payload = getResources().availableWithAdRemoval;
                    } else if (data.data === 'VK Day limit exceeded') {
                        payload = getResources().dayLimitExceeded;
                    }
                }
                break;
            case 403:
                payload = getResources().hintNotCompliantError
                break;
            case 404:
                payload = getResources().generationRequestNotFoundError
                break;
            case 409:
                payload = getResources().userNotFoundError
                break;
            case 415:
                payload = getResources().formatNotSupportedTryDifferentSize
                break;
            case 429:
                payload = getResources().processingNotPossibleWaitForPrevious
                break;
        }
    }
    return payload;
}