import React, {createContext, useReducer} from 'react';
import {SET_CACHE} from "../types";
import {cacheReducer} from "./cacheReducer";

export const CacheContext = createContext(null);

export const CacheState = ({ children }) => {

    const initialState = {
        cache: {}
    };

    const [state, dispatch] = useReducer(cacheReducer, initialState);

    const setCache = (uid, src) => {
        const payload = {
            uid: uid,
            src: src
        }
        dispatch({type: SET_CACHE, payload})
    }

    return (
        <CacheContext.Provider value={{
            setCache,
            cache: state.cache
        }}>
            {children}
        </CacheContext.Provider>
    );
}