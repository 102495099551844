import bridge from "@vkontakte/vk-bridge";
import {API_ENDPOINT, initHref} from "./initHref";
import axios from "axios";

export const downloadImage = (uid, upscale) => {

    const params = new URLSearchParams(initHref());
    const platform = params.get('vk_platform');

    if (platform === 'mobile_android' || platform === 'mobile_ipad' || platform === 'mobile_iphone') {

        bridge.send("VKWebAppDownloadFile", {"url": API_ENDPOINT + 'image/' + uid + initHref() + '&resolution=' + upscale ? 'upscale' : 'png', "filename": uid + '.png'})
            .then(data => {
                console.log("Image uploaded")
            }).catch(error => {
            console.log(error)
        })
    } else {

        axios.get(API_ENDPOINT + 'image/' + uid + initHref(), {
            params: {
                resolution: upscale ? 'upscale' : 'full'
            },
            responseType: upscale ? 'blob' : 'json'
        }).then(response => {

            const data = response.data

            if (upscale) {
                const url = window.URL.createObjectURL(data)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', uid + '.png');
                document.body.appendChild(link);
                link.click();
            } else {
                if (data.status === 'OK') {

                    const src = data.data;

                    let a = document.createElement("a");
                    a.href = src;
                    a.download = uid + '.png';
                    a.click();
                }
            }


        }).catch(error => {
            console.log(error)
        });
    }
}