import React, {useEffect, useState} from "react";
import classes from './css/AiCard.module.css'
import axios from "axios";
import {API_ENDPOINT, initHref} from "../utils/initHref";
import {Spinner, useModalRootContext} from "@vkontakte/vkui";
import refresh from '../img/refresh_outline_28.svg'
import ReactPlayer from "react-player";

export const VideoCard = ({ uid, image }) => {

    const [src, setSrc] = useState(null);
    const { updateModalHeight } = useModalRootContext();

    useEffect(() => updateModalHeight, [src])

    useEffect(() => {
        axios.get(API_ENDPOINT + 'image/' + uid + initHref())
            .then(response => {
                const data = response.data
                if (data.status === 'OK') {
                    const src = data.data
                    setSrc(src);
                }
            }).catch(error => {
            console.log(error)
        });
    },[])

    return (
        <div className={classes.container}>
            { src !== null ?
                <div className={classes.player_wrapper}
                     style={(image !== undefined && image.resolution === 'Landscape') ? { paddingTop: '56.25%'} : { paddingTop: '100%' }}
                >
                    <ReactPlayer
                        className={classes.react_player}
                        url={src}
                        width='100%'
                        height='100%'
                        loop={true}
                        playing={true}
                        onError={(e) => {
                            e.target.src = refresh
                        }}
                    />
                </div>
                :
                <Spinner size="medium"/>
            }
        </div>

    )
}