import {
    GET_DISCOVER_START,
    GET_DISCOVER_SUCCESS,
    LIKE_SUCCESS,
    SET_DISCOVER_IMAGE,
    SET_DISCOVER_PAGE,
    UNMOUNT_DISCOVER
} from "../types";

const handlers = {
    [UNMOUNT_DISCOVER]: state => ({...state, last: false, images: [], page: 0}),
    [SET_DISCOVER_PAGE]: state => ({...state, page: state.page + 1}),
    [SET_DISCOVER_IMAGE]: (state, { payload }) => ({...state, image: payload}),
    [GET_DISCOVER_START]: state => ({...state, isLoading: true}),
    [GET_DISCOVER_SUCCESS]: (state, { payload }) => {
        const newImages = payload.requests.content.filter(
            contentImage => !state.images.some(stateImage => stateImage.uid === contentImage.uid)
        );
        return {...state,
            isLoading: false,
            images: [...state.images, ...newImages],
            last: payload.requests.last
        }
    },
    [LIKE_SUCCESS]: (state, { payload }) => {
        const newImages = state.images.map(image => {
            if (image.uid === payload.like.id) {
                if (payload.like.likes) {
                    return { ...image, i_like: payload.like.likes, likes: image.likes + 1 };
                } else {
                    return { ...image, i_like: payload.like.likes, likes: image.likes - 1 };
                }
            } else {
                return image;
            }
        })
        let newImage = state.image
        if (newImage !== null) {
            if (newImage.uid === payload.like.id) {
                if (payload.like.likes) {
                    newImage = { ...state.image, i_like: payload.like.likes, likes: state.image.likes + 1 };
                } else {
                    newImage =  { ...state.image, i_like: payload.like.likes, likes: state.image.likes - 1 };
                }
            }
        }
        return {...state,
            images: [...newImages],
            image: newImage
        };
    },
    DEFAULT: state => state
};

export const discoverReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};