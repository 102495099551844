import React, {createContext, useReducer} from 'react';
import {discoverReducer} from "./discoverReducer";
import {API_ENDPOINT, initHref} from "../../utils/initHref";
import {
    GET_DISCOVER_START,
    GET_DISCOVER_SUCCESS,
    LIKE_SUCCESS, SET_DISCOVER_IMAGE,
    SET_DISCOVER_PAGE,
    UNMOUNT_DISCOVER
} from "../types";
import axios from "axios";

export const DiscoverContext = createContext(null);

export const DiscoverState = ({ children }) => {

    const initialState = {
        isLoading: false,
        page: 0,
        images: [],
        image: null,
        last: false
    };

    const [state, dispatch] = useReducer(discoverReducer, initialState);

    const setImage = (payload) => {
        dispatch({type: SET_DISCOVER_IMAGE, payload});
    }

    const setPage = () => {
        dispatch({type: SET_DISCOVER_PAGE});
    }

    const unmount = () => {
        dispatch({type: UNMOUNT_DISCOVER});
    }

    const fetchImages = async (page, size, order) => {

        if (state.last) return;

        dispatch({type: GET_DISCOVER_START});

        axios.get(API_ENDPOINT + 'discover' + initHref(),
            {
                params: {
                    page,
                    size,
                    order
                }
            })
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    dispatch({type: GET_DISCOVER_SUCCESS, payload});
                }
            }).catch(error => {
            console.log(error)
        })
    }

    const like = async (uid) => {
        axios.post(API_ENDPOINT + `like/${uid}` + initHref())
            .then(response => {
                const payload = response.data
                if (payload.status === 'OK') {
                    dispatch({type: LIKE_SUCCESS, payload});
                }
            }).catch(error => {
            console.log(error)
        })
    }

    const complaint = (uid) => {
        axios.post(API_ENDPOINT + `complaint/${uid}` + initHref())
            .then(response => {
            }).catch(error => {
            console.log(error)
        })
    }

    return (
        <DiscoverContext.Provider value={{
            setPage,
            fetchImages,
            setImage,
            like,
            unmount,
            complaint,
            isLoading: state.isLoading,
            page: state.page,
            images: state.images,
            image: state.image,
            last: state.last
        }}>
            {children}
        </DiscoverContext.Provider>
    );
}