import React, {useContext, useEffect, useRef} from 'react';
import {
    Button,
    ButtonGroup,
    FormItem,
    Group,
    ModalPage,
    ModalPageHeader,
    PanelHeaderButton,
    PanelHeaderClose,
    Platform,
    Popover,
    Textarea,
    useAdaptivityConditionalRender,
    usePlatform
} from "@vkontakte/vkui";
import {
    Icon20AddCircleFillBlue,
    Icon20CheckCircleFillGreen,
    Icon20CompassOutline,
    Icon20DeleteOutline,
    Icon20DownloadOutline,
    Icon20LikeOutline,
    Icon20ViewOutline,
    Icon24Dismiss,
    Icon24RefreshOutline,
    Icon28LikeFillRed,
    Icon28PlaySpeedOutline,
    Icon56CompassOutline,
    Icon56HideOutline
} from "@vkontakte/icons";
import {ImageCard} from "../../components/ImageCard";
import classes from "../../components/css/AiCard.module.css";
import {ProfileContext} from "../../context/profile/ProfileState";
import {CreatorContext} from "../../context/creator/CreatorState";
import {VideoCard} from "../../components/VideoCard";
import {getResources} from "../../utils/languages";
import {downloadImage} from "../../utils/helpers";
import axios from "axios";
import {API_ENDPOINT, initHref} from "../../utils/initHref";

const MODAL_PAGE_CREATE = 'create_image_screen';
const MODAL_PAGE_DELETE = 'delete_image_screen';
const MODAL_CARD_ADS = 'ads_card';

export const ProfileModalPage = ({id, modalBack, changeActiveModal}) => {

    const platform = usePlatform();
    const { sizeX } = useAdaptivityConditionalRender();

    const intervalRef = useRef();

    const {image, setImage, updateDiscover, setDeleteStatus, like, createUpscale} = useContext(ProfileContext)
    const {isLoading, userIsPaid, status, setPrompt, setStyle, setResolution, createImage, createVideo, setImageUid, unmount, error} = useContext(CreatorContext)

    const update = (uid) => {
        image.discover = !image.discover
        updateDiscover(uid)
    }

    const animate = (uid) => {
        if (image !== null) {
            setImageUid(image)
            setPrompt(image.user_message)
            createVideo(uid);
        }
    }

    const onCreate = () => {
        if (image !== null) {

            setPrompt(image.user_message)
            setStyle(image.style)
            setResolution(image.resolution)

            if (userIsPaid || platform === Platform.IOS) {
                createImage(image.user_message, image.style, image.resolution);
            } else {
                changeActiveModal(MODAL_CARD_ADS)
            }
        }
    }

    useEffect(() => {
        setDeleteStatus(null)
        return () => {
            unmount(false);
        }
    }, [])

    useEffect(() => {
        if (status === 'START_GENERATION') {
            changeActiveModal(MODAL_PAGE_CREATE)
        }
    }, [status])

    useEffect(() => {
        if (image.upscale !== undefined
            && (image.upscale_status === 'UPSCALE_QUEUE' || image.upscale_status === 'UPSCALE_TAKEN')) {

            const fetchWithDelay = () => {
                intervalRef.current = setTimeout(getImageContent, 6000);
            }

            fetchWithDelay();

        } else {
            clearTimeout(intervalRef.current);
        }
        return () => {
            clearTimeout(intervalRef.current);
        };
    }, [image]);

    const getImageContent = () => {
        axios.get(API_ENDPOINT + 'image/' + image.uid + initHref(), {
            params: {
                resolution: 'content'
            }
        }).then(response => {
            const data = response.data
            if (data.status === 'OK') {
                const image = data.request
                console.log("image: " + image)
                setImage(image)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const addButtonGroup = () => {
        if (image.status === 'IMAGE_CLOSED') {
            return (
                <ButtonGroup mode="horizontal" gap="m" stretched>
                    { (image.resolution === 'Stories' || image.resolution === 'Portrait' || platform === Platform.IOS) ? null :
                        <Button
                            style={{marginBottom: 6}}
                            before={<Icon28PlaySpeedOutline/>}
                            size="l"
                            align="center"
                            appearance="accent"
                            mode="outline"
                            stretched
                            disabled={isLoading || image.user_message === ''}
                            onClick={() => animate(image.uid)}>
                            {getResources().animate}
                        </Button>
                    }
                    <Button
                        style={{marginBottom: 6}}
                        size="l"
                        appearance="accent"
                        mode="outline"
                        stretched={image.resolution === 'Stories' || image.resolution === 'Portrait' || platform === Platform.IOS}
                        before={(image.discover === null || image.discover === false) ? <Icon56CompassOutline width={24} height={26}/> : <Icon56HideOutline width={24} height={26}/>}
                        disabled={isLoading || image.user_message === ''}
                        onClick={() => update(image.uid)}>
                        { image.resolution === 'Stories' || image.resolution === 'Portrait' || platform === Platform.IOS ?
                            (image.discover === null || image.discover === false) ? getResources().share : getResources().hide : null
                        }
                    </Button>
                    <Button
                        style={{marginBottom: 6}}
                        size="l"
                        appearance="accent"
                        mode="outline"
                        before={<Icon24RefreshOutline />}
                        disabled={isLoading || image.user_message === ''}
                        onClick={() => onCreate()}
                    />
                </ButtonGroup>
            )
        }
        if (image.status === 'VIDEO_CLOSED') {
            return (
                <ButtonGroup mode="horizontal" gap="m" stretched>
                    <Button
                        style={{marginBottom: 6}}
                        before={(image.discover === null || image.discover === false) ? <Icon56CompassOutline width={24} height={26}/> : <Icon56HideOutline width={24} height={26}/>}
                        size="l"
                        appearance="accent"
                        mode="outline"
                        stretched
                        disabled={isLoading || image.user_message === ''}
                        onClick={() => update(image.uid)}>
                        {(image.discover === null || image.discover === false) ? getResources().share : getResources().hide}
                    </Button>
                </ButtonGroup>
            )
        }
    }

    const PopoverWithTriggerClick = () => {
        return (
            <Popover
                trigger="click"
                id="menupopup"
                role="dialog"
                aria-labelledby="menubutton"
                content={({onClose}) => (
                    <div>
                        <Button
                            role="menuitem"
                            after={<Icon20CheckCircleFillGreen/>}
                            align={'center'}
                            appearance={'accent'}
                            stretched={true}
                            rounded={false}
                            mode={'outline'}
                            disabled={false}
                            size={'m'}
                            loading={false}
                            onClick={() => {downloadImage(image.uid)}}>
                            X1
                        </Button>
                        <Button
                            role="menuitem"
                            after={image.upscale === undefined ? <Icon20AddCircleFillBlue/> : <Icon20CheckCircleFillGreen/>}
                            align={'center'}
                            appearance={'accent'}
                            stretched={true}
                            rounded={false}
                            mode={'outline'}
                            disabled={!userIsPaid}
                            size={'m'}
                            loading={image.upscale !== undefined
                                && (image.upscale_status === 'UPSCALE_QUEUE' || image.upscale_status === 'UPSCALE_TAKEN')}
                            onClick={() => {image.upscale_status !== undefined && image.upscale_status === 'UPSCALE_CLOSED' ? downloadImage(image.uid, true) : createUpscale(image.uid)}}>
                            X2
                        </Button>
                    </div>
                )}
            >
                <button className={classes.block_right} id="menubutton" aria-controls="menupopup">
                    <Icon20DownloadOutline/>
                </button>
            </Popover>
        );
    };

    return (
        <ModalPage
            id={id}
            onClose={modalBack}
            settlingHeight={100}
            dynamicContentHeight
            size='s'
            hideCloseButton={platform === Platform.IOS}
            header={
                <ModalPageHeader
                    before={
                        sizeX.compact &&
                        platform === Platform.ANDROID && (
                            <PanelHeaderClose className={sizeX.compact.className} onClick={modalBack} />
                        )
                    }
                    after={
                        platform === Platform.IOS && (
                            <PanelHeaderButton onClick={modalBack}>
                                <Icon24Dismiss />
                            </PanelHeaderButton>
                        )
                    }
                >
                </ModalPageHeader>
            }>
            <Group style={{marginLeft: 16, marginRight:16}}>
                { image &&
                    <>
                        { image.status === 'IMAGE_CLOSED' &&
                            <ImageCard uid={image.uid} style={image.style} prompt={image.user_message}/>
                        }
                        { image.status === 'VIDEO_CLOSED' &&
                            <VideoCard uid={image.uid} image={image}/>
                        }

                        <div className={classes.like_container}>
                            <button
                                className={classes.like_container}
                                onClick={() => like(image.uid)}
                            >
                                {image.i_like ?
                                    <Icon28LikeFillRed width={20} height={20}/>
                                    :
                                    <Icon20LikeOutline width={20} height={20}/>
                                }
                                <div style={{paddingTop: 4, paddingLeft: 4, fontSize: 12}}>
                                    {image.likes}
                                </div>
                            </button>
                            <button className={classes.like_container}>
                                <Icon20ViewOutline/>
                                <div style={{paddingTop: 4, paddingLeft: 4, fontSize: 12}}>
                                    {image.views}
                                </div>
                            </button>

                            <div className={classes.block_container}/>

                            <button className={classes.block_right}>
                                <Icon20DeleteOutline
                                    onClick={() => changeActiveModal(MODAL_PAGE_DELETE)}
                                />
                            </button>
                            { image.status === 'IMAGE_CLOSED' &&
                                <PopoverWithTriggerClick/>
                            }
                        </div>

                        <div style={{marginTop: 8, marginBottom: 12}}>
                            <FormItem
                                status={error ? 'error' : ''}
                                bottom={error ? error : ''}
                                noPadding>
                                <Textarea disabled={true} value={image && image.user_message}/>
                            </FormItem>
                        </div>

                        { addButtonGroup() }

                        { (image.discover === null || image.discover === false) ?
                            <button className={classes.like_container}>
                                <Icon20CompassOutline width={16} height={16}/>
                                <div style={{fontSize: 12, marginLeft: 4, marginTop: 2, textAlign: 'center', opacity: 0.6}}>
                                    {getResources().discoverSection}
                                </div>
                            </button>
                            : null
                        }
                    </>
                }
            </Group>
        </ModalPage>
    )
}



