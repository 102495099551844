import React, {useContext, useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    Caption,
    CardScroll,
    Div,
    FormItem,
    Group,
    Platform,
    SegmentedControl,
    Textarea,
    Tooltip,
    usePlatform
} from '@vkontakte/vkui';
import {StyleCard} from '../../components/StyleCard';
import {CreatorContext} from "../../context/creator/CreatorState";
import {Icon16InfoOutline} from '@vkontakte/icons';
import {ProfileContext} from "../../context/profile/ProfileState";
import {getResources} from "../../utils/languages";
import classes from '../../components/css/AiCard.module.css'
import logo from '../../img/ic_launcher_foreground.png'

const options = [
    {
        id: getResources().square,
        label: '1:1',
        value: 'Square'
    },
    {
        id: getResources().stories,
        label: '9:16',
        value: 'Stories'
    },
    {
        id: getResources().landscape,
        label: '16:9',
        value: 'Landscape'
    },
    {
        id: getResources().portrait,
        label: '4:5',
        value: 'Portrait'
    }
];

const telegram = window.Telegram.WebApp

const urlParams = new URLSearchParams(window.location.search);
const vk_language = urlParams.get('vk_language');

export const CreatePanel = ({ openModal, descriptionOpen, descriptionOpenEn, openBaseWithAction, openAds }) => {

    const platform = usePlatform();

    const { isLoading, userIsPaid, prompt, setPrompt, styles, style, setStyle, getStyles, createImage, status, error, unmount, resolution, setResolution } = useContext(CreatorContext)
    const { user } = useContext(ProfileContext)

    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        getStyles();
        return () => {
            unmount(true)
        }
    }, [])

    useEffect(() => {
        if (status === 'START_GENERATION') {
            openModal()
        }
    }, [status])

    const onCreate = () => {
        if (userIsPaid || platform === Platform.IOS) {
            createImage();
        } else {
            openAds()
        }
    }

    const selectStyle = (title) => {
        if (style && title === style) {
            setStyle(null)
        } else {
            setStyle(styles.find(style => style.title === title).title)
        }
    }

    const checkPrompt = () => {
        return prompt === '' || prompt.charAt(0) === ' ' || isLoading;
    }

    const openSnackBar = () => {
        if (isVisibleBalance()) {
            openBaseWithAction()
        }
    }

    const isVisibleBalance = () => {
        if (telegram.initData !== '') {
            return true
        }
        return platform !== Platform.IOS;
    };

    const openDescription = () => {
        if (vk_language !== null
            && vk_language !== undefined) {
            if (vk_language === 'ru') {
                descriptionOpen()
            }
        } else if (telegram.initData !== '') {
            if (telegram.initDataUnsafe.user.language_code === 'ru') {
                descriptionOpen()
            }
        } else {
            descriptionOpenEn()
        }
    }

    const createStyle = (index, st) => {
        if (isVisibleBalance()) {
            return (
                <StyleCard
                    key={'StyleCard' + index}
                    selected={style !== null ? style===st.title : false}
                    style={st}
                    paid={st.paid}
                    onClick={!st.paid ? () => selectStyle(st.title) : () =>
                        openSnackBar()
                    }
                />
            )
        } else if (!st.paid) {
            return (
                <StyleCard
                    key={'StyleCard' + index}
                    selected={style !== null ? style===st.title : false}
                    style={st}
                    paid={st.paid}
                    onClick={!st.paid ? () => selectStyle(st.title) : () =>
                        openSnackBar()
                    }
                />
            )
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return (
        <Group style={{height: '80vh'}}>
            <CardScroll size="s">
                {styles && styles.map((st, index) =>
                    createStyle(index, st)
                )}
            </CardScroll>
            <FormItem
                top={<React.Fragment>{getResources().size}{resolution ? <span>: {options.find(value => value.value === resolution).id}</span> : ': ' + getResources().square}</React.Fragment>}
            >
                <SegmentedControl
                    size='m'
                    name="resolution"
                    defaultValue={ resolution !== null ? resolution : "Square" }
                    onChange={(value) => setResolution(value)}
                    options={options}
                />
            </FormItem>
            <div style={{marginTop: 8}}>
                <FormItem
                    status={ error ? 'error' : '' }
                    bottom={ error ? error : '' }
                    top={
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{marginRight: 4}}>
                                {getResources().hintInput}
                            </div>
                            <Tooltip text={getResources().hintLanguage}
                                     shown={tooltip}
                                     onClose={() => setTooltip(false)}>
                                <Icon16InfoOutline style={{paddingTop: 2}} onClick={() => setTooltip(!tooltip)}/>
                            </Tooltip>
                        </div>
                    } htmlFor='prompt' bottomId='promptExample'>
                    <Textarea
                        id="emample"
                        aria-describedby="promptExample"
                        placeholder={getResources().europeanGirlCinematicLight}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                    />
                </FormItem>
            </div>
            <div style={{margin:12}}>
                <Button
                    size="l"
                    align="center"
                    appearance="accent"
                    stretched
                    onClick={() => onCreate()}
                    disabled={checkPrompt()}>
                    {getResources().create}
                </Button>
            </div>
            <Div style={{textAlign: 'right', color: '#2688eb'}}>
                <Caption className={classes.hint_helper} level="1" onClick={() => openDescription()}>{getResources().createHint}</Caption>
            </Div>
            {
                platform !== Platform.IOS &&
                <div
                    className={classes.footer}
                    onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.badmitry.alimagegenerator") }
                >
                    <Avatar
                        size={32}
                        src={logo}/>
                    <div className={classes.footer_text}>{getResources().basedOn}</div>
                </div>
            }
        </Group>
    )
};
