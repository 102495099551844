import React, {useContext} from 'react';
import {Button, ButtonGroup, ModalCard, Spacing} from "@vkontakte/vkui";
import { Icon56BlockOutline } from '@vkontakte/icons';
import {DiscoverContext} from "../../context/discover/DiscoverState";
import {getResources} from "../../utils/languages";

const MODAL_PAGE_DISCOVER = 'discover_image_screen';

export const ComplaintModalCard = ({id, modalBack, changeActiveModal}) => {

    const { image, complaint } = useContext(DiscoverContext)

    const click = () => {
        complaint(image.uid)
        changeActiveModal(MODAL_PAGE_DISCOVER)
    }

    return (
        <ModalCard
            id={id}
            onClose={modalBack}
            icon={<Icon56BlockOutline />}
            header={getResources().reportIssue}
            subheader={getResources().reportViolation}
            actions={
                <React.Fragment>
                    <Spacing size={16} />
                    <ButtonGroup size="l" mode="vertical" stretched>
                        <Button
                            key="complaint"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => click()}
                        >
                            {getResources().complain}
                        </Button>
                        <Button
                            key="back"
                            size="l"
                            mode="secondary"
                            stretched
                            onClick={() => changeActiveModal(MODAL_PAGE_DISCOVER)}
                        >
                            {getResources().goBack}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        ></ModalCard>
    )
}



