import {
    CHANGE_DISCOVER, DELETE_STATUS, DELETE_SUCCESS,
    GET_PROFILE_START,
    GET_PROFILE_SUCCESS,
    LIKE_SUCCESS, PROFILE_UPSCALE_ERROR, PROFILE_UPSCALE_START,
    SET_PROFILE_IMAGE,
    SET_PROFILE_PAGE,
    UNMOUNT_PROFILE,
    UPDATE_DISCOVER_SUCCESS, UPSCALE_ERROR, UPSCALE_START
} from "../types";

const handlers = {
    [UNMOUNT_PROFILE]: state => ({...state, last: false, images: [], page: 0}),
    [SET_PROFILE_PAGE]: state => ({...state, page: state.page + 1}),
    [CHANGE_DISCOVER]: state => ({...state, discover: !state.discover, last: false, images: [], page: 0}),
    [SET_PROFILE_IMAGE]: (state, { payload }) => ({...state, image: payload}),
    [GET_PROFILE_START]: state => ({...state, isLoading: true}),
    [GET_PROFILE_SUCCESS]: (state, { payload }) => {
        const newImages = payload.requests.content.filter(
            contentImage => !state.images.some(stateImage => stateImage.uid === contentImage.uid)
        );

        return {...state,
            user: payload.user,
            isLoading: false,
            images: [...state.images, ...newImages],
            last: payload.requests.last
        }
    },
    [LIKE_SUCCESS]: (state, { payload }) => {
        const newImages = state.images.map(image => {
            if (image.uid === payload.like.id) {
                if (payload.like.likes) {
                    return { ...image, i_like: payload.like.likes, likes: image.likes + 1 };
                } else {
                    return { ...image, i_like: payload.like.likes, likes: image.likes - 1 };
                }
            } else {
                return image;
            }
        })
        let newImage = state.image
        if (newImage !== null) {
            if (newImage.uid === payload.like.id) {
                if (payload.like.likes) {
                    newImage = { ...state.image, i_like: payload.like.likes, likes: state.image.likes + 1 };
                } else {
                    newImage =  { ...state.image, i_like: payload.like.likes, likes: state.image.likes - 1 };
                }
            }
        }
        return {...state,
            images: [...newImages],
            image: newImage
        };
    },
    [UPDATE_DISCOVER_SUCCESS]: (state, { payload }) => {
        let newImages = state.images
            .map(image => {
                if (image.uid === payload.request.uid) {
                    return { ...image, discover: payload.request.discover};
                } else {
                    return image;
                }
            })
        return {...state,
            images: [...newImages]
        }
    },
    [DELETE_SUCCESS]: (state, { payload }) => {
        const newImages = state.images.filter(
            image => image.uid !== payload
        );
        return {...state,
            images: [...newImages],
            statusDelete: 'SUCCESS'
        }
    },
    [DELETE_STATUS]: (state, { payload }) => ({...state, statusDelete: payload}),
    [PROFILE_UPSCALE_START]: (state, { payload }) => {
        const newImages = state.images.map(image => {
            if (image.uid === state.image.uid) {
                return { ...image, upscale: payload, upscale_status: 'UPSCALE_QUEUE' };
            } else {
                return image;
            }
        })
        let newImage =  { ...state.image, upscale: payload, upscale_status: 'UPSCALE_QUEUE' };
        return {...state,
            images: [...newImages],
            image: newImage
        };
    },
    [PROFILE_UPSCALE_ERROR]: (state, { payload }) => ({...state}),
    DEFAULT: state => state
};

export const profileReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};