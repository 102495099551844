import {
    CREATOR_UPSCALE_ERROR,
    CREATOR_UPSCALE_START,
    GEN_IMAGE_COUNTER,
    GENERATION_ERROR,
    GENERATION_IN_PROGRESS,
    GENERATION_SUCCESS,
    GET_STYLES,
    PRE_GENERATE,
    PROFILE_UPSCALE_ERROR,
    PROFILE_UPSCALE_START, SET_CREATOR_IMAGE,
    SET_IMAGE_UID,
    SET_PROMPT,
    SET_RESOLUTION,
    SET_STYLE,
    SET_USER_ADS,
    START_GENERATION,
    UNMOUNT_CREATE,
    UNMOUNT_CREATE_NOT_CHANGE_LOADING
} from "../types";
import {getResources} from "../../utils/languages";

const handlers = {
    [UNMOUNT_CREATE]: state => ({...state, status: null, style: state.error === getResources().styleUnavailablePurchaseInProfile ? null : state.style, error: null, isLoading: false}),
    [UNMOUNT_CREATE_NOT_CHANGE_LOADING]: state => ({
        ...state,
        status: state.error === getResources().styleUnavailablePurchaseInProfile ? null : state.status,
        style: state.error === getResources().styleUnavailablePurchaseInProfile ? null : state.style,
        error: null
    }),
    [GET_STYLES]: (state, {payload}) => ({...state, styles: payload}),
    [SET_USER_ADS]: (state, {userIsPaid}) => ({...state, userIsPaid: userIsPaid}),
    [SET_STYLE]: (state, {payload}) => ({...state, style: payload}),
    [SET_RESOLUTION]: (state, {payload}) => ({...state, resolution: payload}),
    [SET_PROMPT]: (state, {payload}) => ({...state, prompt: payload, error: null}),
    [PRE_GENERATE]: state => ({...state, isLoading: true, status: 'PRE_GENERATE', error: null}),
    [START_GENERATION]: (state, {payload}) => ({...state, uid: payload, status: 'START_GENERATION'}),
    [GENERATION_IN_PROGRESS]: (state, {payload}) => ({...state, status: payload.status, genProgress: payload.data}),
    [GENERATION_SUCCESS]: (state, {payload}) => ({...state, status: payload.status, imageUid: payload.status === 'IMAGE_CLOSED' ? state.uid : state.imageUid, genProgress: payload.data, isLoading: false}),
    [GENERATION_ERROR]: (state, {payload}) => ({...state, status: 'IMAGE_ERROR', error: payload, isLoading: false}),
    [GEN_IMAGE_COUNTER]: (state, {payload}) => ({...state, genCounter: payload}),
    [SET_IMAGE_UID]: (state, {payload}) => ({...state, imageUid: payload}),
    [SET_CREATOR_IMAGE]: (state, {payload}) => ({...state, image: payload}),
    [CREATOR_UPSCALE_START]: (state, { payload }) => {
        let newImage =  { ...state.image, upscale: payload, upscale_status: 'UPSCALE_QUEUE' };
        return {...state,
            image: newImage
        };
    },
    [CREATOR_UPSCALE_ERROR]: (state, { payload }) => ({...state}),
    DEFAULT: state => state
};

export const creatorReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};