import React, {useContext, useEffect} from 'react';
import {Button, ButtonGroup, ModalCard, Spacing} from "@vkontakte/vkui";
import {Icon48StarsCircleFillViolet} from '@vkontakte/icons';
import {CreatorContext} from "../../context/creator/CreatorState";
import {getResources} from "../../utils/languages";

const MODAL_PAGE_CREATE = 'create_image_screen';
const MODAL_PAGE_PAYMENT = 'payment_page';

export const AdsModalCard = ({id, modalBack, changeActiveModal}) => {

    const { status, prompt, style, resolution, createImage } = useContext(CreatorContext)

    useEffect(() => {
        if (status === 'START_GENERATION') {
            changeActiveModal(MODAL_PAGE_CREATE)
        }
    }, [status])

    return (
        <ModalCard
            id={id}
            onClose={modalBack}
            icon={<Icon48StarsCircleFillViolet width={56} height={56}/>}
            header={getResources().keepCreatingMasterpieces}
            subheader={getResources().chooseOptionToContinue}
            actions={
                <React.Fragment>
                    <Spacing size={16} />
                    <ButtonGroup size="l" mode="vertical" stretched>
                        <Button
                            key="complaint"
                            size="l"
                            mode="primary"
                            stretched
                            onClick={() => changeActiveModal(MODAL_PAGE_PAYMENT)}
                        >
                            { getResources().disableAds }
                        </Button>
                        <Button
                            key="back"
                            size="l"
                            mode="secondary"
                            stretched
                            onClick={() => createImage(prompt, style, resolution)}
                        >
                            {getResources().watchAds}
                        </Button>
                    </ButtonGroup>
                </React.Fragment>
            }
        ></ModalCard>
    )
}



