export const GET_VK_USER_SHOW_LOADING = 'GET_USER_SHOW_LOADING';
export const GET_VK_USER_INFO_SUCCESS = 'GET_VK_USER_INFO_SUCCESS';
export const GET_VK_USER_INFO_ERROR = 'GET_VK_USER_INFO_ERROR';
export const GET_REWARD_ADS_SUCCESS = 'GET_REWARD_ADS_SUCCESS';
export const GET_REWARD_ADS_ERROR = 'GET_REWARD_ADS_ERROR';
export const SET_USER_ADS = 'SET_USER_ADS';

export const PRE_GENERATE = 'PRE_GENERATE'
export const START_GENERATION = 'START_GENERATION'
export const GENERATION_IN_PROGRESS = 'GENERATION_IN_PROGRESS'
export const GENERATION_SUCCESS = 'GENERATION_SUCCESS'
export const GENERATION_ERROR = 'GENERATION_ERROR'
export const UNMOUNT_CREATE = 'UNMOUNT_CREATE'
export const UNMOUNT_CREATE_NOT_CHANGE_LOADING = 'UNMOUNT_CREATE_NOT_CHANGE_LOADING'
export const GEN_IMAGE_COUNTER = 'GEN_IMAGE_COUNTER'
export const SET_RESOLUTION = 'SET_RESOLUTION'
export const SET_IMAGE_UID = 'SET_IMAGE_UID'
export const SET_CREATOR_IMAGE = 'SET_CREATOR_IMAGE'

export const PROFILE_UPSCALE_START = 'PROFILE_UPSCALE_START'
export const PROFILE_UPSCALE_ERROR = 'PROFILE_UPSCALE_ERROR'

export const CREATOR_UPSCALE_START = 'CREATOR_UPSCALE_START'
export const CREATOR_UPSCALE_ERROR = 'CREATOR_UPSCALE_ERROR'

export const GET_PROFILE_START = 'GET_PROFILE_START'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const SET_PROFILE_PAGE = 'SET_PROFILE_PAGE'
export const UNMOUNT_PROFILE = 'UNMOUNT_PROFILE'
export const CHANGE_DISCOVER = 'CHANGE_DISCOVER'
export const DELETE_SUCCESS = 'DELETE_SUCCESS'
export const DELETE_STATUS = 'DELETE_STATUS'

export const GET_DISCOVER_START = 'GET_DISCOVER_START'
export const GET_DISCOVER_SUCCESS = 'GET_DISCOVER_SUCCESS'
export const SET_DISCOVER_PAGE = 'SET_DISCOVER_PAGE'
export const SET_CACHE = 'SET_CACHE'
export const UNMOUNT_DISCOVER = 'UNMOUNT_DISCOVER'

export const SET_PROMPT = 'SET_PROMPT'
export const SET_STYLE = 'SET_STYLE'
export const GET_STYLES = 'GET_STYLES'

export const LIKE_SUCCESS = 'LIKE_SUCCESS'

export const UPDATE_DISCOVER_SUCCESS = 'UPDATE_DISCOVER_SUCCESS'

export const SET_DISCOVER_IMAGE = 'SET_DISCOVER_IMAGE'
export const SET_PROFILE_IMAGE = 'SET_PROFILE_IMAGE'

export const GET_PRODUCTS_START = 'GET_PRODUCTS_START'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export const SET_PRODUCTS = 'SET_PRODUCTS'
export const GET_PAYMENT_URL_START = 'GET_PAYMENT_URL_START'
export const GET_PRODUCT_URL_SUCCESS = 'GET_PRODUCT_URL_SUCCESS'
export const GET_PRODUCT_URL_ERROR = 'GET_PRODUCT_URL_ERROR'

export const COMPLAINT_SUCCESS = 'COMPLAINT_SUCCESS'




